import { createI18n } from 'vue-i18n'

const messages = {
    th: {
        header: {
            name: 'บริษัท แอสเซทอัพ จำกัด',
            home: 'หน้าแรก',
            about: 'เกี่ยวกับเรา',
            contact: 'ข้อมูลติดต่อ'
        },
        serviceSaleHouse: {
            service: 'บริการฝากขายบ้านและที่ดิน',
            detail1: 'อีกหนึ่งบริการที่จะอำนวยความสะดวกให้กับเจ้าของทรัพย์ทุกท่าน',
            detail2: 'ที่ต้องการมีผู้ช่วยจัดการทรัพย์สิน',
            detail3: 'ไม่ว่าจะเป็นการให้เช่าบ้าน คอนโด หรือต้องการขาย',
            detail4: 'ง่าย ๆ เพียงส่งรายละเอียดและรูปภาพ เบอร์โทรศัพท์ รวมถึงพิกัดเบื้องต้นเข้ามาให้ทางทีมงาน',
            detail5: 'และทางทีมงานจะติดต่อท่านกลับไป เพื่อทำการถ่ายรูปและทำรายละเอียดเพิ่มเติมอีกครั้ง',
            pricerate: 'อัตราค่าบริการ',
            rate1: 'คอมมิชชั่น 3 - 5% แล้วแต่รูปแบบการทำงาน และข้อตกลง',
            rate2: 'คอมมิชชั่นและเงื่อนไข ตามตกลง',
            send: 'ส่งรายละเอียดข้อมูลทรัพย์ของท่านได้ที่'
        },
        product: {
            see: 'สั่งซื้อ',
            register: 'ลงทะเบียนเพื่อสั่งซื้อแบบแปลน',
            nameForm: 'ชื่อ',
            lastnameForm: 'นามสกุล',
            addressForm: 'ที่อยู่',
            tel: 'เบอร์โทรศัพท์',
            direction: 'เลือกทิศที่ดินที่ท่านต้องการ',
            north: 'ทิศเหนือ',
            south: 'ทิศใต้',
            east: 'ทิศตะวันออก',
            west: 'ทิศตะวันตก',
            landWide: 'ขนาดที่ดิน (กว้าง : เมตร)',
            landHeight: 'ขนาดที่ดิน (ยาว : เมตร)',
            imgLand: 'รูปภาพโฉนด',
            directionLabel: 'ทิศที่ดินของท่าน',
            buy: 'สั่งซื้อ',
            detail: 'รายละเอียดสินค้า'
        },
        thaiHomePlan: {
            name: 'แบบบ้านสไตล์ไทย',
            detail: 'แอสเซทอัพ ส่งมอบอีก 1 แปลนบ้านคุณภาพที่เหมาะอย่างยิ่งสำหรับเมืองไทย ที่ให้ความลงตัวสูงสุด ตั้งแต่หน้าตาภายนอก อันเป็นที่นิยมชมชอบมาอย่างยาวนาน แต่ภายในให้ผังและฟังก์ชั่นการอยู่อาศัย ที่เป็นมาตรฐานสากลระดับโลกแบบ TUD เพื่อให้เข้ากับแนวคิด " สร้างบ้านครั้งเดียว อยู่สบายชั่วชีวิต "',
            planCS1: 'แปลนบ้าน CS1',
            subCS1: 'บ้านสไตล์ไทย ในโทนอบอุ่น หลังคาปั้นหยาแบบคลาสสิค แบบบ้านหน้ากว้าง โอ่อ่า ภูมิฐาน เหมาะกับที่ดินหน้ากว้าง 19.50 m. ลึก 13 m. ขึ้นไป เหมาะอย่างยิ่งสำหรับที่ดินทิศเหนือ - ใต้',
            shortDesCS1: 'บ้านสไตล์ไทย ในโทนอบอุ่น หลังคาปั้นหยาแบบคลาสสิค แบบบ้านหน้ากว้าง โอ่อ่า ภูมิฐาน เหมาะกับที่ดินหน้ากว้าง...',
            moreDetail: 'รายละเอียดแปลนบ้าน',
            detailCS1_1: '3 ห้องนอน 3 ห้องน้ำ (2 มาสเตอร์แบบห้องน้ำในตัว) พร้อม walk in closet',
            detailCS1_2: 'ห้องน้ำฟังก์ชั่น TUD ใช้สบาย ลงตัว เป็นสัดส่วน เหมาะกับสรีระการใช้งาน',
            detailCS1_3: 'ห้องน้ำมีอากาศหมุนเวียนตลอดวัน แห้งสนิท ช่วยลดอุบัติเหตุภายใน',
            detailCS1_4: 'บ้านประหยัดพลังงาน ด้วยแสงและอากาศไหลเวียนทั้งหลัง ปลอดมุมอับ',
            detailCS1_5: 'ห้องรับแขก ที่รับประทานอาหาร พร้อมครัวไทยแบบเปิด',
            detailCS1_6: 'ระบบพื้นแบบ no step design อยู่สบายไร้สะดุด',
            detailCS1_7: 'ระเบียงพักผ่อน สำหรับห้องนอนด้านหน้าบ้าน',
            detailCS1_8: '2 ห้องเก็บของภายนอก (ใหญ่ 1 เล็ก 1)',
            detailCS1_9: 'ที่จอดรถ 2 คัน เข้าออกสบาย',
            detailCS1_10: 'มุมซักล้างเฉพาะส่วน',
            areaCS1: 'พื้นที่ใช้สอย 140 ตารางเมตร',
            detailAreaCS1: 'เหนือกว่าด้วยการออกแบบตามหลักสรีรศาสตร์ ผนวกหลักทางวิศวกรรมและสถาปัตยกรรม รับประกันงานสถาปัตยกรรม 1 ปี และงานโครงสร้าง 5 ปี',
            detailPriceCS1: 'ราคาค่าแบบพร้อมยื่นขออนุญาต เพียง 19,900 รวมภาษีแล้ว งบก่อสร้าง ระหว่าง 2,200,000 - 2,500,000 ราคาแน่นอนแจ้งให้ทราบในวันที่ทำสัญญา หลังจากตรวจสอบราครวัสดุล่าสุดแล้ว',
            specialCS1: 'พิเศษ! ฟรีค่าแบบ กรณีทำสัญญาก่อสร้างกับทางบริษัทแอสเซสอัพ กรณีซื้อเฉพาะแบบไปก่อสร้างเอง ทางบริษัทยินดีให้คำปรึกษาระหว่างก่อสร้าง แต่หากให้ทางบริษัทช่วยก่อสร้าง ทางบริษัทจะใช้เฉพาะทีมงานช่างที่เชี่ยวชาญการทำบ้านฟังก์ชั่นคุณภาพในการช่วยก่อสร้างเท่านั้น เพื่อรับประกันระดับคุณภาพ ความพึงพอใจ และได้ฟังก์ชั่น TUD เต็มรูปแบบ บริการให้คำปรึกษาด้านการขอสินเชื่อเพื่อกู้ซื้อบ้าน',
            noteCS1: 'หมายเหตุ',
            detailNoteCS1_1: 'ราคาก่อสร้างไม่รวมเสาเข็ม กรณีต้องมีการตอกเสาในบางพื้นที่',
            detailNoteCS1_2: 'ราคาก่อสร้าง เฉพาะส่วนโครงสร้าง ไม่รวมงานตกแต่งภายใน',
        },
        home: {
            question: 'มีคำถามที่อยากจะถามเราหรือไม่?',
            office: 'เดินทางมายังบริษัท',
            lineID: 'ส่งข้อความหาเรา',
            currentProject: 'โครงการล่าสุด',
            nameCurrentProject: 'ชื่อโครงการ',
            detailCurrentProject: 'รายละเอียดโครงการ',
            nameCompany: 'ศูนย์ออกแบบ รับสร้างบ้านสวย พร้อมฟังก์ชั่นคุณภาพ',
            detailCompany: 'ออกแบบ - ก่อสร้าง - วางผัง - ฟังก์ชั่น',
            moreDetail: 'รายละเอียดเพิ่มเติม',
            ourService: 'บริการของเรา',
            newContent: 'บทความครบเครื่องเรื่องสร้างบ้าน',
            pastProject: 'บ้านพร้อมขาย',
            design: 'ออกแบบแปลนบ้าน',
            construction: 'ก่อสร้างบ้านและอาคาร',
            planandfunction: 'วางผังและฟังก์ชั่นบ้าน',
            plansuccess: 'แบบแปลนบ้านสำเร็จรูป',
            pool: 'สระว่ายน้ำและออนเซ็น',
            render: 'งานเรนเดอร์บ้านและอาคาร',
            planlayout: 'วางผังโครงการจัดสรร',
            twohandhome: 'คู่หูดูบ้านมือสอง',
            drone: 'บริการถ่ายภาพมุมสูง',
            sell: 'ฝากขายบ้านและที่ดิน',
            readMore: 'อ่านเพิ่มเติม',
            contentPostTitle1: 'แอสเซทอัพ ทีมออกแบบและก่อสร้างบ้าน พร้อมฟังก์ชั่นอยู่อาศัยที่ดีที่สุดหนึ่งเดียวของไทย',
            shortDescriptionPost1: 'ด้วยทางบริษัทตระหนักดีว่า คุณภาพชีวิตของผู้อยู่อาศัยในบ้าน จะไม่มีทางเกิดขึ้นเลย หากบ้าน...',
            //Projects
            titleProject1: 'บ้านเดี่ยวนอกโครงการ',
            priceProject1: '9.9 ล้านบาท',
            shortDescriptionProject1: 'ขายพร้อมเฟอร์นิเจอร์ พร้อมอยู่ เพียง 10 นาที จากถนนนิมมานเหมินทร์',
            bedRoomProject1: '3 ห้องนอน',
            toiletProject1: '3 ห้องน้ำ',

            titleProject2: 'บ้านเดี่ยวนอกโครงการ',
            priceProject2: '5.5 ล้านบาท',
            shortDescriptionProject2: 'ขายพร้อมเฟอร์นิเจอร์',
            bedRoomProject2: '3 ห้องนอน',
            toiletProject2: '3 ห้องน้ำ',

            titleProject3: 'บ้านเดี่ยวทำเลเมือง',
            priceProject3: '2.xx ล้านบาท',
            shortDescriptionProject3: 'บอกลาบ้านมาตรฐานเดิม ๆ แล้วมาเริ่มสิ่งใหม่ดี ๆ ที่นี่',
            bedRoomProject3: '3 ห้องนอน',
            toiletProject3: '2 ห้องน้ำ',

            titleProject4: 'SD Condo สวนดอก',
            priceProject4: '1.89 ล้านบาท',
            shortDescriptionProject4: 'ห้องรับแขกและห้องครัว ขนาด 40 ตรม.',
            bedRoomProject4: '1 ห้องนอน',
            toiletProject4: '1 ห้องน้ำ',

            //Posts
            titlePost1: 'แอสเซทอัพ ทีมออกแบบและก่อสร้างบ้าน พร้อมฟังก์ชั่นอยู่อาศัยที่ดีที่สุดหนึ่งเดียวของไทย',
            ShortDescriptionPost1: 'ด้วยทางบริษัทตระหนักดีว่า คุณภาพชีวิตของผู้อยู่อาศัยในบ้าน จะไม่มีทางเกิดขึ้นเลย หากบ้าน...',
            titlePost2: 'อยากสร้างบ้านสักหลัง แต่ไม่รู้จะเริ่มยังไง?',
            ShortDescriptionPost2: 'หนึ่งปัญหาใหญ่ ที่ชวนปวดหัวที่สุดของคนที่กำลังอยากสร้างบ้าน คือ ไม่รู้จะเริ่มตรงไหนก่อนนี่แหละ วันนี้แอสเสทอัพ...',
            titlePost3: 'ที่มาของคำถามปวดตับ กับรับสร้างบ้าน ตารางเมตรละเท่าไหร่? ตอนที่ 1',
            ShortDescriptionPost3: 'อ่านดูคำโปรยจั่วหัว แล้วคงจะรู้สึกแปลก ๆ ก็ในเมื่อ ฉันกำลังจะสร้างบ้าน ก็ต้องอยากรู้สิ ว่าต้นทุนสร้างบ้าน มันจะราคาเท่าไหร่...',
            titlePost4: 'บ้านเดี่ยวนอกโครงการ ฮาเวนนา สารภี สำหรับคนมีความฝัน ถึงบ้านแห่งความสุข',
            ShortDescriptionPost4: 'เชื่อว่าหลาย ๆ คน คงเจอปัญหาเดียวกัน เวลาที่ตระเวณหาบ้านสร้างเสร็จ พร้อมขายสักที่...',

        },
        contact: {
            contact: 'ติดต่อเรา',
            contactDetail: 'ข้อมูลการติดต่อ',
            headOffice: 'สำนักงานใหญ่',
            addressOffice: '318/92 ซอย ลาดพร้าว 87 (จันทราสุข) แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง จังหวัดกรุงเทพมหานคร 10310',
            socialMedia: 'ช่องทางอื่น ๆ',
            sendMessage: 'ส่งข้อความหาเรา',
            name: 'ชื่อ - นามสกุล',
            email: 'อีเมล',
            subject: 'หัวเรื่อง',
            message: 'ข้อความ',
            send: 'ส่ง'
        },
        singleProject: {
            price: 'ราคา',
            moreDetail: 'รายละเอียดเพิ่มเติม',
            location: 'สถานที่ตั้ง',
            area: 'พื้นที่',
            gallery: 'คลังรูปภาพ',

            cateProject1: 'บ้านเดี่ยวนอกโครงการ',
            description1Project1: 'บ้านสวยระดับคุณภาพ ตกแต่งพร้อมเข้าอยู่ ที่ออกแบบ ก่อสร้างด้วยผังและฟังก์ชันแบบ TUD ที่เดียวของไทย อยู่สบายกว่าบ้านทั่วไป',
            description2Project1: 'พร้อมด้วยทำเลศักยภาพ ที่ให้ความสะดวกสบายในการใช้ชีวิต เข้าถึงเมืองในแค่ไม่กี่นาที ชิดติดธรรมชาติแค่ไม่กี่ก้าว',
            description3Project1: 'พื้นที่ใช้สอย 260 ตารางเมตร บนที่ดิน 54 ตารางวา พร้อมเคาน์เตอร์รับประทานอาหาร มุมซักรีด เครื่องใช้ไฟฟ้า แอร์ 5 หลัง น้ำอุ่นแบบผสม พร้อมเรนชาวเวอร์ และอ่างอาบน้ำ สระว่ายน้ำ 2*7 เมตร ที่นั่งชมวิวเขา เฟอร์นิเจอร์ครบ พร้อมเข้าอยู่',
            priceProject1: '9.9 ล้านบาท',
            bedRoomProject1: '3 ห้องนอน',
            toiletProject1: '3 ห้องน้ำ',
            locationProject1: 'ตำบลสุเทพ เพียง 10 นาที จากถนนนิมมานเหมินทร์',
            areaProject1: '260 ตารางเมตร',
            livingRoomProject1: '2 ห้องรับแขก',

            cateProject2: 'บ้านเดี่ยวนอกโครงการ ทำเลดี ที่สันกำแพง',
            description1Project2: 'เหมาะสำหรับผู้ที่ต้องการบ้าน ที่มีทำเลเดินทางสะดวก ตั้งอยู่ใกล้ถนนหลัก 1317 ถือเป็นจุดที่เดินทางสะดวกมากที่สุดแห่งหนึ่งของเชียงใหม่ เดินทางเข้าออกนอกเมือง หรือพื้นที่ต่าง ๆ ได้อย่างรวดเร็ว ด้วยถนน 6 เลนรอบทิศ',
            description2Project2: 'บ้าน 2 ชั้น ขนาด 220 ตารางเมตร บนที่ดิน 100 ตารางวา มาพร้อมอินเทอร์เน็ต กล้องวงจรปิด ประปาหมู่บ้าน พร้อมระบบกรอง ที่จอดรถ 3-4 คัน พร้อมลานพักผ่อนและสนามหญ้า',
            description3Project2: 'ขายพร้อมเฟอร์นิเจอร์',
            priceProject2: '5.5 ล้านบาท',
            bedRoomProject2: '3 ห้องนอน',
            toiletProject2: '3 ห้องน้ำ',
            locationProject2: 'อำเภอสันกำแพง',
            areaProject2: '220 ตารางเมตร',
            livingRoomProject2: '1 ห้องรับแขก',

            cateProject3: 'บ้านเดี่ยวทำเลเมือง ฮาเวนนา สารภี',
            description1Project3: 'บ้านเดี่ยวราคาสบายกระเป๋า พร้อมฟังก์ชั่นเข้าใจคนอยู่ อีกหนึ่งทำเลเดินทางสะดวกสบาย ตั้งอยู่ตำบลท่าวังตาล อำเภอสารภี ระหว่างวงแหวนรอบ 2 และรอบ 3',
            description2Project3: 'ภายนอกสวยคลาสสิค ภายในล้ำกว่าด้วยการวางผังแบบคุณภาพ',
            description3Project3: 'พื้นที่ใช้สอยกว่า 140 ตารางเมตร บนที่ดินเริ่มต้น 50 ตารางวาขึ้นไป ครัวพร้อมพื้นที่ทานอาหาร การวางผังและฟังก์ชั่นลงตัวทุกสัดส่วน',
            priceProject3: '2.xx ล้านบาท',
            bedRoomProject3: '3 ห้องนอน',
            toiletProject3: '2 ห้องน้ำ',
            locationProject3: 'อำเภอสารภี',
            areaProject3: '140 ตารางเมตร',
            livingRoomProject3: '1 ห้องรับแขก',

            cateProject4: 'SD Condo สวนดอก',
            description1Project4: 'คอนโดสำหรับผู้ที่ชื่นชอบความเงียบสงบอยู่สบาย ในทำเลใจกลางเมือง อำเภอเมือง สวนดอก เชียงใหม่ ใกล้นิมมานเหมินทร์ และแหล่งอำนวยความสะดวกมากมาย ทั้งสนามบิน ตลาดต้นพยอม ร้านสะดวกซื้อ ห้างสรรพสินค้า ริมปิงซุปเปอร์มาร์เก็ต โรงพยาบาลสวนดอก โรงพยาบาลเชียงใหม่ราม มหาวิทยาลัยเชียงใหม่',
            description2Project4: 'และเหมาะอย่างยิ่ง สำหรับแพทย์และพยาบาล หรือผู้ที่กำลังศึกษาอยู่คณะต่าง ๆ ในโรงพยาบาลสวนดอก',
            description3Project4: 'คอนโดมาพร้อมห้องครัวและห้องรับแขก พื้นที่ใช้สอย 40 ตารางเมตร พร้อมเฟอร์นิเจอร์แต่งครบ แอร์ 2 เครื่อง เครื่องทำน้ำอุ่น และเครื่องซักผ้า พร้อมบริการซักผ้าฟรี 30 ชิ้น/เดือน และทำความสะอาดห้องฟรี 2 ครั้ง/เดือน จากส่วนกลาง**',
            priceProject4: '1.89 ล้านบาท',
            bedRoomProject4: '1 ห้องนอน',
            toiletProject4: '1 ห้องน้ำ',
            locationProject4: 'สวนดอก',
            areaProject4: '40 ตารางเมตร',
            livingRoomProject4: '1 ห้องรับแขก'
        },
        serviceBuddy: {
            buddy: 'คู่หูดูบ้านมือสอง',
            bq: 'การซื้อบ้านมือสองนั้น สามารถเป็นได้ทั้งประโยชน์ หรือกลายเป็นโทษมหาศาล เพราะสิ่งสำคัญในการดูบ้านมือสองนั้น ก็คือวิจารณญานและประสบการณ์ ซึ่งเป็นสิ่งที่คนส่วนใหญ่ไม่มี เพราะไม่ได้คลุกคลีกับเหตุการณ์ดังกล่าวเป็นประจำ อาจมีลูกค้าหลายท่านที่ ซื้อบ้านมือสอง เพราะต้องการแค่ทำเล แล้วรื้อบ้านเดิมทิ้ง ตรงนี้คงไม่ใช่ประเด็น เพราะยังไงก็คือการซื้อเพื่อรื้อ โครงสร้างและระบบภายในจะดีหรือแย่ ก็คงไม่มีผลอะไรแล้ว',
            detail1: 'แต่หากเป็นการซื้อเพื่อเข้าอยู่ต่อนั้น สถานการณ์ดังกล่าวจะเปลี่ยนไปทันที เพราะการซื้อเพื่ออยู่นั้น สภาพของบ้านต้องอยู่ในลักษณะที่ดี เหมาะแก่การอยู่ต่อได้เลย ไม่ใช่อยู่ไป ซ่อมไป ควักเงินเพิ่มไปเรื่อย ๆ แต่แก้ไม่จบ เพราะปัญหาโครงสร้าง หรือเลวร้ายที่สุด คือต้องรื้อทิ้งโครงสร้างเดิม เริ่มต้นที่ 0 ใหม่อีกครั้ง ผิดโจทย์ที่ต้องการประหยัดเงินไปทันที',
            detail2: 'ปัญหาที่เจอ ไล่ตั้งแต่สภาพโครงสร้างอาคารโดยรวม เสา ผนัง ฐานราก โครงสร้างหลังคาระบบฝ้าเพดาน ระบบไฟฟ้าภายใน ระบบน้ำ ตำหนิต่าง ๆ รวมไปถึงลักษณะผังและฟังก์ชั่นของบ้านหลังนั้น ๆ เผื่อในกรณีที่ต้องมีการปรับสภาพ ว่าอยู่ในข่ายที่ทำได้หรือไม่',
            detail3: 'เพราะความตั้งใจหนึ่งของคนที่ซื้อบ้านมือสอง คือต้องการได้บ้านที่มีราคาถูกท้องตลาด หรือได้สิ่งที่คุ้มค่า ไม่ทางใดก็ทางหนึ่ง แต่หากต้องควักงบประมาณมหาศาล เพื่อแก้ไขบ้านแล้วละก็ คงไม่ใช่เรื่องที่น่ายินดีแต่อย่างใด และก็จะเข้าข่ายเสียน้อยเสียยาก เสียมากเสียง่าย',
            detail4: 'ทางทีมงานของบริษัทเอง ได้มีโอกาสเข้าประเมินราคางานรีโนเวทบ้านและอาคารอยู่เป็นระยะ ซึ่งบ้านหลาย ๆ หลังนั้น ผิดวัตถุประสงค์ของผู้ซื้อ ที่ซื้อเพราะอยากอยู่ แต่ไม่ได้อยากซ่อมหนัก หากเพราะขาดประสบการณ์การดูบ้าน จึงทำให้ได้บ้านมือสอง ที่มีราคาแพงกว่าสภาพจริงและต้องเสียเงินซ่อมแซมจำนวนมหาศาล ชนิดที่ว่า ซื้อบ้านใหม่ยังคุ้มและสบายใจกว่าหลายเท่า',
            detail5: 'แอทเซทอัพ จึงมีบริการเสริม คู่หูดูบ้านมือสอง เข้ามาช่วยแก้ปัญหาดังกล่าว เพื่อช่วยให้คนที่สนใจซื้อบ้านมือสอง สามารถซื้อบ้านได้อย่างมั่นใจยิ่งขึ้น',
            titleGood: 'สุดยอดข้อดีของการมีคู่หูดูบ้าน',
            good1: '✅ ลดโอกาสการขาดทุนจากการซื้อบ้านมือสอง',
            good2: '✅ ช่วยให้ได้บ้านที่มีสภาพสมราคา ไม่ย้อมแมว',
            good3: '✅ ช่วยลดแรงกดดันจากนายหน้า (กรณีที่ผู้ขายมีตัวแทนอยู่)',
            good4: '✅ ได้ข้อมูลประกอบการต่อรองราคากับผู้ขายได้สมเหตุผลมากขึ้น',
            good5: '✅ ดูบ้านได้รายละเอียดครอบคลุมมากขึ้นกว่าเดิม',
            good6: '✅ ช่วยวางแผนค่าใช้จ่ายซ่อมแซม กรณีต้องมีการปรับปรุงบ้านเพิ่มเติมก่อนเข้าอยู่',
            good7: '✅ ช่วยวิเคาระห์การวางผังและฟังก์ชั่นเบื้องต้น พร้อมทั้งวิธีแก้ไข เพื่อให้อยู่สบายระยะยาว',
            good8: '✅ ให้คำแนะนำครอบคลุมถึงเรื่องการทำสัญญา และเรื่องจำเป็นอื่นๆในการซื้อขายบ้าน',
            good9: '✅ ลดโอกาสเสียเปรียบในหลากหลายแง่มุม ที่เกิดขึ้นได้เสมอในการซื้อขายบ้าน',
            good10: '✅ ลดค่าใช้จ่ายได้หลักแสน หรือหลักล้าน ด้วยต้นทุนเพียงแค่ 3,500 - 5,000 บาท',
            price: 'ค่าบริการเริ่มต้นเพียง',
            price1: '💰 3,500 บาท /ครั้ง ( สำหรับบ้านตรม ต่ำกว่า 250 )',
            price2: '💰 5,000 บาท /ครั้ง ( สำหรับบ้านขนาดใหญ่กว่า 250 ตรม )',
            detailPrice: 'กรณีหน้างานมีระยะเกินกว่า 20 km มีค่าใช้จ่ายเพิ่มเติม 500 บาท/ทุก ๆ ระยะ 20 กม',
            detailEnd: 'คุ้มค่าและลดภาระงบบานปลายจากการซื้อบ้าน',
            detailEnd2: 'แอทเซทอัพ ทุกคำตอบของบ้านเพื่อคุณภาพชีวิต',
        },
        serviceDesign: {
            startprice: 'ราคาเริ่มต้นเพียง ตรมละ 450-650 บาท',
            headtable: 'สำหรับท่านเจ้าของโครงการ สามารถติดต่อแจ้งรายละเอียดโครงการของท่าน เพื่อรับข้อเสนองานออกแบบราคาพิเศษ โดยตรงได้ที่ 083-424-9090',
            krong: 'โครงสร้างการคิดราคาค่าออกแบบบ้านโครงการ (ใช้แบบแปลนเดียวกัน)',
            lang: 'หลังที่',
            priceplan: 'ราคาแปลน',
            title1: 'เพราะการออกแบบบ้านที่เหมาะสมกับการอยู่อาศัยจริง',
            title2: 'คือก้าวแรกสู่การมีคุณภาพชีวิตที่ดี',
            detialServiceDesign: 'แอสเซทอัพตระหนักถึงปัญหาการออกแบบบ้านในเมืองไทย ที่ไม่ได้ตอบโจทย์การอยู่อาศัยอย่างแท้จริง ซึ่งเป็นปัญหาที่คนส่วนใหญ่ไม่รู้ ที่แปลนบ้านส่วนใหญ่ขาดคุณภาพ เพราะเข้าใจว่าแบบแปลนบ้าน ออกแบบมาโดยสถาปนิค ผู้ซึ่งน่าจะเป็นมืออาชีพ จึงไม่น่าจะมีปัญหาใด ๆ ด้วยความไว้ใจดังกล่าว เจ้าของบ้านจึงไม่มีความคิดในการตรวจสอบ อีกทั้งโดยทั่วไป การออกแบบบ้าน มักจะเป็นความรับผิดชอบในส่วนของสถาปนิคเท่านั้น แตกต่างจากแอสเซทอัพ ที่มีทั้งนักวางแผนและฟังก์ชั่นเฉพาะทาง ที่ปรึกษา หัวหน้าทีมก่อสร้าง วิศวกร เข้ามาช่วยดูแลการวางแปลนบ้าน เพื่อสร้างความสมบูรณ์แบบที่เหนือกว่า',
            detail1: 'คนส่วนใหญ่นั้น ไม่ทราบว่า แปลนบ้านที่เราเห็นส่วนใหญ่จนชินตานั้น มักจะมีปัญหาในเรื่องของการวางผังและฟังก์ชั่นซ่อนอยู่เสมอ หากใช้คำว่าผังและฟังก์ชั่น คนส่วนใหญ่อาจไม่คุ้น แต่หากใช้คำว่า ฮวงจุ้ย หรือหลักการธรรมชาติ อาจทำให้คนเชื่อมโยงได้ง่ายกว่า',
            detail2: 'ใช่ครับ ในขณะที่คนส่วนใหญ่ให้ความสำคัญกับฮวงจุ้ยบ้านอย่างมาก ถ้าไม่ได้พูดเรื่องฮวงจุ้ยบ้านละก็ ไม่มีทางจบ แต่ที่น่าแปลกและดูขัดแย้งมากที่สุดก็คือ แบบบ้านที่ได้รับส่วนใหญ่นั้น กลับมีลักษณะผิดฮวงจุ้ย ผิดธรรมชาติอยู่ในแปลนบ้านเต็มไปหมด แต่เนื่องจากหน้าตาภายนอกของบ้าน ดูสวยถูกใจ และให้ความเชื่อใจว่า คนออกแบบน่าจะไว้ใจเรื่องคุณภาพแบบได้ด้วยเช่นกัน แต่ขึ้นว่า การทำงานกับคนแล้ว ย่อมมีความแตกต่างกันโดยสิ้นเชิงไม่มีมาตรฐานใด ๆ มากำกับได้เลย',
            example: 'ตัวอย่างปัญหาแปลนบ้าน ที่เจอได้บ่อยจนชินตา',
            ex1: '1. ลักษณะแปลนบ้านภายในที่ซ้ำกันไปมา',
            ex2: '2. มีผัง ตำแหน่งห้องต่าง ๆ ไม่เหมาะกับที่ดินในทิศใด ๆ เลย',
            ex3: '3. ฟังก์ชั่นประหลาด เช่น การต้องนั่งทานข้าวหน้าห้องน้ำ',
            ex4: '4. เหม็นกลิ่นห้องน้ำในบ้าน เพราะตำแหน่งอยู่กลางบ้าน',
            ex5: '5. ห้องน้ำมีลักษณะมืด อับทึบ ชื้น และต้องเปิดไฟตลอดเวลาที่ใช้งาน',
            ex6: '6. การมีลักษณะภายในที่นำไปสู่อุบัติเหตุ',
            ex7: '7. การขาดความสะดวกสบายในการใช้งาน',
            ex8: '8. การวางตำแหน่งผังไม่เหมาะกับลักษณะการใช้ชีวิตของเจ้าของบ้าน',
            ex9: '9. ปัญหาน้ำท่วมหลังคา',
            ex10: '10. การต้องต่อเติมโครงสร้าง',
            detail3: 'ปัญหาเหล่านี้จะสร้างเครียดสะสม จนเสียสุขภาพจิต อีกทั้งยังต้องเสียเงินก้อนใหญ่ เพื่อมาแก้ไข “การออกแบบที่ผิดพลาดของทีมออกแบบ”',
            detail4: 'ซึ่งเหล่านี้เป็นเหตุผลสำคัญที่แอทเซทตั้งใจสร้างทีมออกแบบเฉพาะทางเพื่อช่วยแก้ไขปัญหาดังกล่าว โดยทีมเฉพาะทางนั้น มีแกนหลักการออกแบบแปลนคือ “นักวางผังและฟังก์ชั่นเฉพาะทาง” ที่เชี่ยวชาญงานวางผังและฟังก์ชั่นระดับโลกแบบ TUD (True Universal Design) ที่ทำงานควบคู่ไปกับสถาปนิคและวิศวกร สร้างความครบถ้วนและสมบูรณ์แบบที่เหนือกว่า ซึ่งแตกต่างจากการทำแบบบ้านโดยทั่วไป ที่เป็นหน้าที่ของสถาปนิคเท่านั้น จึงทำให้พบข้อบกพร่องได้ค่อนข้างมาก เนื่องจากเป็นการทำงานเพียงคนเดียว',
            detail5: 'แบบบ้านที่ออกแบบโดยทีมงานของแอทเซทอัพ จึงไม่ใช่แค่บ้านที่มีภายนอกสวยสะดุดตา สไตล์ถูกใจ แต่ยังมีคุณภาพผัง ฟังก์ชั่นการอยู่อาศัยสมบูรณ์กว่าทุก ๆ แบบบ้าน เพราะขึ้นแปลนหลังต่อหลัง ปลอดการใช้แปลนบ้านซ้ำ อีกทั้งมีการวิเคราะห์จัดวางฟังก์ชั่นการใช้งานให้ครอบคลุมการใช้งานสำหรับตลอดช่วงวัยชีวิต รองรับทุกสถานการณ์การเปลี่ยนแปลง โดยไม่จำเป็นต้องย้ายหรือซื้อบ้านหลังใหม่ เพื่อแก้ไขปัญหา “ผังและฟังก์ชั่นด้อยคุณภาพ” แต่อย่างใด',
            detail6: 'อีกทั้งค่าใช้จ่ายในการออกแบบบ้านที่ให้ผังและฟังก์ชั่นคุณภาพ ไม่ได้แตกต่างจากแบบบ้านทั่ว ๆ ไปแต่อย่างใด และหากเทียบในมุมคุณภาพแล้ว อาจจะถือได้ว่าถูกกว่า เพราะในแง่คุณค่าแล้ว ไม่สามารถใช้ตัวเลขสูงต่ำมาชี้วัดได้',
            detail7: 'ข้อดีต่าง ๆ เหล่านี้ ทำให้เจ้าของบ้านทุกหลัง ได้บ้านที่ประหยัด มีราคาสร้างคุ้มค่า ลดค่าใช้จ่ายต่าง ๆ ที่จะเกิดตามมา จากการต้องแก้ไขการมีผังและฟังก์ชั่นผิด ๆ ในภายหลัง ซึ่งเป็นงบประมาณอันมหาศาล',
            promotion: 'รับโปรโมชั่นราคาพิเศษ',
            titleTeam: 'เหตุผลสำคัญที่แอสเซทอัพตั้งใจสร้างทีมออกแบบเฉพาะทางเพื่อช่วยแก้ปัญหาดังกล่าว',
            detailTeam: 'โดยทีมเฉพาะทางนั้น มีแกนหลังการออกแบบแปลนคือ "นักวางผังและฟังก์ชั่นเฉพาะทาง" ที่เชี่ยวชาญงานวางผังและฟังก์ชั่นระดับโลก TUD (True Universal Design) ที่ทำงานควบคู่ไปกับสถาปนิคและวิศวกร สร้างความสมบูรณ์แบบเหนือที่เหนือกว่า',
            oneService: 'อีกหนึ่งบริการดี ๆ หนึ่งเดียวของไทย ที่หาได้ที่บริษัทแอสเซทอัพเท่านั้น',
            priceTitle: 'ราคางานออกแบบชุดมาตรฐาน',
            titleModal: 'รับโปรโมชั่นพิเศษสำหรับลูกค้าในเว็บไซต์เท่านั้น',
            detailModal: 'กดคัดลอกโค้ดส่วนลดด้านล่างนี้ แล้วนำไปใส่ในขั้นตอนการชำระเงิน',
            gotoshop: 'ไปหน้าสินค้า',
            close: 'ปิดหน้าต่างนี้',
            copysuccess: 'คัดลอกสำเร็จ',
            buy: 'สั่งซื้อบริการนี้',
            receive: 'สิ่งที่ได้รับในชุดมาตรฐาน',
            price: 'ปกติ ตารางเมตรละ 450 บาท เท่านั้น',
            standard: 'ชุดมาตรฐาน หมายถึง เอกสารงานแบบที่ประกอบไปด้วย',
            standard1: '1. แปลนก่อสร้างแบบสมบูรณ์ รวมถึงระบบน้ำ ไฟ สาธารณูปโภค',
            standard2: '2. ลายเซ็นสถาปนิค วิศวกรที่ทำการตรวจสอบ',
            standard3: '3. วางผังและตรวจสอบผัง ฟังก์ชั่นด้วยนักวางฟังก์ชั่นเฉพาะทาง',
            standard4: '4. รายการคำนวน (BOQ)',
            standard5: '5. งานขออนุญาติก่อสร้าง (กรณีให้ทางแอทเซทอัพดำเนินการก่อสร้าง)',
            standard6: '5. งานปรึกษาระหว่างก่อสร้าง',
            serviceSerm: 'แอสเซทอัพยังมีบริการออก',
            planspecial: 'แบบแปลนบ้านราคาพิเศษ',
            serviceSerm2: ' นอกเหนือจากชุดมาตรฐาน รวมทั้ง',
            plan: 'แปลนบ้านสำเร็จรูป',
            serviceSerm3: 'ให้เลือก เพียงแค่แจ้งงบประมาณและความต้องการของท่าน แล้วรอให้ทางผู้เชี่ยวชาญติดต่อกลับ เพื่อให้คำปรึกษาได้ทันที',
            special: 'พิเศษสุด ๆ สำหรับผู้ที่ทำสัญญาก่อสร้างกับทางบริษัท ทางเราพร้อมส่งมอบแปลนบ้านก่อสร้างที่มาพร้อมผังและฟังก์ชั่น TUD ให้เจ้าของบ้าน ฟรี!!'
        },
        serviceBuild: {
            photoClt: 'ประมวลภาพผลงานออกแบบและก่อสร้างบางส่วน',
            title1: 'ไม่เพียงเฉพาะงานออกแบบบ้าน ที่เรายกระดับความละเอียด ใส่ใจ ในส่วนของทีมงานก่อสร้าง ทางแอสเซทอัพก็ใส่ใจไม่แพ้กัน',
            title2: 'เพื่อให้การทำงานก่อสร้างสมบูรณ์แบบ สัมพันธ์กับแปลนบ้านที่มาพร้อมผังกับ',
            function: 'ฟังก์ชั่น TUD',
            subtitle1: 'ไม่เพียงแต่ต้องใช้ทีมงานประจำของทางบริษัทแล้ว ทีมงานก่อสร้างทุก ๆ คนของบริษัทจึงต้องได้ผ่านการอบรมการสร้างบ้านฟังก์ชั่นพิเศษเป็นระยะ ๆ ตลอดปีที่ทำงาน เพื่อพัฒนาฝีมือและมุมมองในการทำงานให้ดียิ่ง ๆ ขึ้น ผสานกับประสบการณ์การทำงานที่มีมากกว่า 10 ปี ทางบริษัทจึงรับประกันได้ว่า เจ้าของบ้านทุกหลังจะได้บ้านที่สวยสมบูรณ์ ตรงตามความฝันของเจ้าของบ้านทุก ๆ ท่านเสมอ',
            priceRate: 'อัตราค่าบริการ ส่วนงานก่อสร้าง',
            priceRate1: '1. คำนวนราคาจริงจาก BOQ ที่ลูกค้านำมา (ใช้เวลาถอดแบบ 2-4 สัปดาห์)',
            deposit: 'มัดจำ',
            condition: 'เงื่อนไข',
            note: 'หมายเหตุ',
            baht: 'บาท',
            for150: 'สำหรับบ้านพื้นที่ต่ำกว่า 150 ตร.ม.',
            note150: 'เงินมัดจำจะถูกนำไปเป็นส่วนลดในส่วนงานก่อสร้าง',
            for150more: 'สำหรับพื้นที่มากกว่า 150 ตร.ม. ขึ้นไป',
            priceRate2: '2. คำนวนราคาจากสเป็กวัสดุ ที่ทางบริษัทกำหนด ราคาอยู่ระหว่าง 1x,xxx - 2x,xxx บาท/ตร.ม. ทั้งนี้สเป็กวัสดุที่ทางบริษัทเลือกให้ จะสอดคล้องกับความต้องการเบื้องต้นของลูกค้า',
            priceRate3: '3. ค่าบริการเฉพาะในส่วนค่าแรง โดยทางลูกค้าจัดเตรียมวัสดุก่อสร้างทั้งหมดให้กับทางบริษัท',
            detailnote: 'ลูกค้าโปรดเตรียมเอกสารใบรายการวัสดุ แปลน ไฟล์ autocad และอื่น ๆ ที่คาดว่าจำเป็นต่อการถอดราคา',
            nohaveboq: 'กรณีลูกค้ายังไม่มีใบรายการวัสดุ (BOQ) ทางบริษัทมีค่าบริการเพิ่มเติมดังนี้',
            detailboq11: 'ค่าบริการ',
            detailboq12: 'ถอดรายการวัสดุ',
            detailboq13: '( BOQ ) เริ่มต้น 5,000 ขึ้นไป',
            detailboq21: 'คู่หูดูแปลนบ้าน',
            detailboq22: '(กรณีลูกค้าอยากให้ทางเราช่วยตรวจสอบคุณภาพแปลน)',
            forCustomer: 'สำหรับลูกค้าที่สนใจแปลนบ้านพร้อมราคาก่อสร้างแบบสำเร็จ กรุณาคลิก',
            pleaseClick: 'แบบบ้านสำเร็จ',
            detailBlockq: 'เพราะการก่อสร้างบ้านให้เข้าใกล้คำว่า "สมบูรณ์แบบ" โดยสัมพันธ์ไปกับแปลนบ้าน TUD ซึ่งมีความละเอียด และต้องการทักษะในการอ่านแปลนสูง เพราะแปลนบ้านแบบ TUD นั้น มีรายละเอียดหลายอย่างต่างจากแปลนบ้านทั่ว ๆ ไป จะเหมือนกันก็เพียงแค่ราคา ที่ไม่ได้แตกต่างจากแปลนบ้านทั่ว ๆ ไปเลย',
            titleTeam: 'ทีมงานทางฝ่ายงานก่อสร้างของทางบริษัท',
            team1: '1. ทีมงานโครงสร้างและอาคาร',
            team2: '2. ทีมโครงสร้างหลังคา',
            team3: '3. ทีมงานระบบไฟฟ้า งานระบบ และ smart home',
            team4: '4. ทีมงานกระเบื้องและพื้น',
            team5: '5. ทีมงานกระจกและงานประตู',
            team6: '6. ทีมบิลท์อินและงานตกแต่งภายใน',
            team7: '7. ทีมระบบสระว่ายน้ำ และออนเซน',
        },
        serviceHomeSuccess: {
            title1: 'สำหรับเจ้าของบ้านที่มองหาความสะดวก รวดเร็ว จากแบบแปลนบ้านสำเร็จรูป',
            subtitle1: 'สามารถค้นหาแบบบ้านได้จากหมวดนี้',
            title2: 'แบบบ้านทุกดีไซน์ ได้รับการออกแบบ วางผัง จากทีมวางผังฟังก์ชั่น สถาปนิค และวิศวกรอย่างเต็มรูปแบบ จึงมั่นใจได้ในเรื่องคุณภาพของแปลนบ้าน',
            plan1: 'แบบบ้านหน้ากว้าง 1 และ 2 ชั้น',
            plan2: 'แบบบ้านแนวลึก 1 และ 2 ชั้น',
            plan3: 'แบบบ้านมินิมอล',
            plan4: 'แบบบ้านสไตล์ไทย',
            plan5: 'แบบร้านกาแฟ และร้านค้า',
            plan6: 'อื่น ๆ',
            cate: 'หมวดหมู่แบบบ้าน',
            cate1: 'แบบบ้านหน้ากว้าง 1 และ 2 ชั้น',
            cate2: 'แบบบ้านแนวลึก 1 และ 2 ชั้น',
            cate3: 'แบบบ้านมินิมอล',
            cate4: 'แบบบ้านสไตล์ไทย',
            cate5: 'แบบร้านกาแฟ และร้านค้า',
            cate6: 'อื่น ๆ'

        },
        serviceRender: {
            titleFeeling1: 'ภาพ 1 ภาพ แทนได้ล้านความหมาย',
            titleFeeling2: ' 1 ล้านความรู้สึกดี ๆ เกิดได้จากการเห็นภาพที่สวยงาม',
            detailTitle: 'ไม่ว่าจะเป็นเจ้าของบ้าน ที่อยากได้รูปบ้านสวย ๆ ไว้ประดับ เป็นที่ระลึกในมุมเก๋ ๆ ของบ้าน หรือท่านเจ้าของโครงการ ที่ต้องการภาพบ้านเพื่อทำการตลาด',
            titleServeiceRender: 'แอสเซทอัพ มีบริการออกแบบและเรนเดอร์ภาพ 3 มิติ รวมถึงการทำคลิปวิดีโอแบบ VR (Virtual Reality) คุณภาพสูง',
            catePricerate: 'หมวดอัตราค่าบริการ',
            buy: 'สั่งซื้อบริการนี้',
            catePricerate1: 'บริการออกแบบและวาดแปลนพื้น (Floor plan)',
            price1: 'ราคา 2,000 บาท',
            catePricerate2: 'บริการปั้นแบบบ้าน',
            price2: 'ราคา 2,500 บาท (เกิน 100 ตรม. คิดเพิ่ม 700 บาท)',
            catePricerate3: 'เรนเดอร์ 3 มิติ บ้าน 1 ชั้น',
            price3: 'ราคา 5,500 บาท',
            catePricerate4: 'เรนเดอร์ 3 มิติ บ้าน 2 ชั้น',
            price4: 'ราคา 5,500 บาท',
            catePricerate5: 'เรนเดอร์ภาพโครงการบ้านจัดสรร',
            price5: 'ราคา 5,000 บาท',
            catePricerate6: 'คลิปภาพเคลื่อนไหว 3 มิติ (Virtual Reality)',
            price6: 'ราคา 20,000 บาท',
            ex: 'ตัวอย่างผลงาน'
        },
        serviceSwimmingPool: {
            title1: 'อีกหนึ่งองค์ประกอบ ที่ช่วยสร้างความสวยงาม และสมบูรณ์แบบให้กับบ้านในฝัน',
            title2: 'คือการมีสระว่ายน้ำหรือออนเซ็น ที่ ๆ จะเป็นศูนย์รวมความสุข ผ่อนคลายให้กับสมาชิกทุกคนในบ้าน หรือแม้กระทั่งเป็นที่นัดหมาย พบปะสังสรรค์กับเพื่อนฝูง ที่จะเปลี่ยนทุกวันธรรมดา ให้เป็นวันพิเศษได้เสมอ ๆ',
            asset: 'แอสเซทอัพ มีทีมงานคุณภาพที่จะให้คำปรึกษา ออกแบบ และก่อสร้างสระว่ายน้ำ พร้อมกับการวางระบบงานระดับมืออาชีพ ให้เหมาะกับความต้องการของท่าน',
            pricerate: 'ค่าบริการงานออกแบบและก่อสร้างสระว่ายน้ำและออนเซ็น',
            detailPricerate1: 'เนื่องจากงานดังกล่าว มีรายละเอียดแตกต่างกันออกไปตามลักษณะหน้างาน รวมทั้งมีความแตกต่างเรื่องขนาดสระ ลักษณะดิน งานระบบสระว่ายน้ำ หรืองานระบบออนเซ็น การเพิ่มเครื่องรักษาอุณหภูมิน้ำ เป็นต้น',
            detailPricerate2: 'ทางบริษัทขอสงวนสิทธิ์ในการเข้าตรวจสอบหน้างาน จึงจะสามารถแจ้งรายละเอียดได้เป็นลำดับต่อไป',
            detailMore: 'ข้อมูลเพิ่มเติม',
            detailmore1: '1. ระบบสระว่ายน้ำเป็นดูดกลับ',
            detailmore2: '2. ระบบสระว่ายน้ำแบบน้ำล้น',
            detailmore3: '3. ระบบออนเซ็น',
            detailmore4: '4. ความแตกต่างของสระว่ายน้ำแบบสำเร็จรูป กับแบบโครงสร้างคอนกรีจเสริมเหล็ก',
            detailmore5: '5. สระว่ายน้ำกับเสาเข็ม',
            detailmore6: '6. เครื่องรักษาอุณหภูมิสระว่ายน้ำ',
        },
        servicePlanHouse: {
            title1: 'บริการวางผังโครงการบ้านจัดสรร',
            detailTitle: 'สำหรับผู้ที่ทำธุรกิจนักพัฒนาอสังหาริมทรัพย์',
            detailService: 'แอสเซทอัพพร้อมเป็นส่วนหนึ่งในการสร้างรากฐานและเติบโตไปด้วยกัน',
            detailService2: 'ด้วยบริการครบวงจร ที่ช่วยให้ลูกค้าที่ประสงค์สร้างบ้านเพื่อขาย สามารถเริ่มต้นวางแผนงานจนจบขั้นตอนได้ครบถ้วน รวดเร็ว มองเห็นประเด็นปัญหารอบทิศ รวมถึงสามารถควบคุมทุนสร้าง มองเห็นผลกำไรอย่างชัดเจน',
            service: 'บริการการบริหารจัดการโครงการบ้านจัดสรร',
            service1: '1. งานวางผังโครงการแบบภาพรวม',
            service2: '2. งานออกแบบบ้านสำหรับโครงการ',
            service3: '3. งานระบบไฟฟ้า สาธารณูปโภค ระบบบำบัด ฯลฯ ภายในโครงการ',
            service4: '4. งานขออนุญาตโครงการบ้านจัดสรร หรือบ้านนอกโครงการ',
            service5: '5. การวางแผนการตลาด ประชาสัมพันธ์',
            service6: '6. การตลาด บ้านและที่ดินออนไลน์',
            service7: '7. บริการออกแบบโลโก้และภาพโปสเตอร์ประชาสัมพันธ์บริษัท',
            service8: '8. อื่น ๆ ที่จำเป็นสำหรับโครงการบ้านจัดสรร',
        },
        servicePlanFunction: {
            planHouseTitle: 'บริการ คู่หูตรวจสอบผังและฟังก์ชั่น',
            detailPlan1 : 'สิ่งสำคัญที่กระทบคุณภาพชีวิตคนมีบ้านมากที่สุด คือการได้บ้านที่มีผังและฟังก์ชั่นด้อยคุณภาพ ซึ่งประเด็นดังกล่าว ถือเป็นปัญหาเงียบที่แพร่หลายอยู่ในเมืองไทย',
            detailPlan2 : 'กว่าที่คนส่วนใหญ่จะรู้สึกถึงปัญหา ก็ต่อเมื่อจ่ายเงินค่าก่อสร้างและย้ายเข้าอยู่แล้ว แต่พออยู่ ๆ ไป กลับได้ความรู้สึกว่า เฮ้ย บ้านแบบนี้ไม่น่าใช่ ทำไมห้องนี้อยู่ตรงนี้ ทำไมห้องน้ำเป็นแบบนั้น ทำไมห้องนี้กลับมาอยู่ตรงนี้ หรือการมีตำแหน่งโต๊ะกินข้าวหน้าห้องน้ำ ห้องน้ำมืด อับ ทึบ กลิ่นห้องน้ำอบอวลอยู่กลางบ้าน ทำไมคนออกแบบทำไว้แบบนี้ ตำแหน่งทุกอย่างประหลาดไปหมด บันไดก็ทำเป็นเลขคี่ตามสูตร ทำไมยังมีอุบัติเหตุอยู่ดี หรือบ้านหลังคาโมเดิร์น พอฝนตก น้ำท่วมฝ้าลงมา ข้าวของเสียหาย หรือแปลนบ้านซ้ำ ๆ ซาก ๆ เหมือนกันไปหมด ต่างกันแค่หน้าตา ฯลฯ',
            detailPlan3 : 'แต่เรื่องคับข้องใจทั้งหมด แก้ไขอะไรไม่ได้ เพราะบ้านทำเสร็จ เงินก็จ่ายไปครบ บางอย่างก็พอแก้ไขได้ แต่ก็ต้องใช้เงิน กลายเป็นงบบาน บางอย่างก็แก้ไม่ได้เลย ต้องทุบทิ้ง ทำใหม่ งบก็ยิ่งลามพาลหงุดหงิด ต้องทนอยู่กันไปแบบนั้น',
            detailPlan4 : 'แท้จริงแล้ว ตัวอย่างปัญหาที่ว่ามา เห็นได้ตั้งแต่ในตัวแปลนบ้าน แต่เนื่องจากเจ้าของบ้านขาดประสบการณ์ดังกล่าว และมีแนวคิด ความเชื่อว่า แปลนบ้านนั้น ได้รับการออกแบบมาเป็นอย่างดี รอบคอบ และรัดกุม',
            bq : ' การออกแบบและสร้างบ้านนั้น เป็นการทำงานกับคน คุณภาพของแปลน จึงขึ้นกับคุณภาพของคน',
            detailPlan5 : 'ดังนั้น แปลนบ้านที่มาจากฝีมือการเขียนแบบแต่ละคน คุณภาพจึงต่างกันโดยสิ้นเชิง การเชื่อว่าทุก ๆ แปลนบ้าน มีคุณภาพเหมือน ๆ กัน จึงไม่คิดตรวจสอบแปลน จึงเป็นความคิดที่อันตราย และสร้างความขาดทุนเป็นอย่างมาก',
            detailPlan6 : 'แอทเซทอัพ จึงมีบริการพิเศษสำหรับเจ้าของบ้านทุก ๆ ท่านที่ต้องการให้มีที่ปรึกษา ช่วยตรวจสอบและกำกับ คุณภาพของแปลนบ้าน ทั้งในส่วนการวางผังและการทำฟังก์ชั่นบ้านในทุกจุด เพื่อให้บ้านในฝันออกมาเป็นที่สุดของความฝันสำหรับทุกคนในครอบครัว',
            duties: 'หน้าที่ของคู่หูดูแปลนบ้าน',
            detailDuties1: '✅ เป็นที่ปรึกษาให้เจ้าของบ้านในทุกเรื่อง ระหว่างออกแบบ',
            detailDuties2: '✅ ช่วยให้คำแนะนำในการปรับแก้ตำแหน่งผังต่างๆให้เหมาะสมกว่าเดิม',
            detailDuties3: '✅ ให้คำแนะนำด้านการวางฟังก์ชั่น ร่วมกับสถาปนิคที่ทำการเขียนแบบ',
            detailDuties4: '✅ ช่วยตรวจสอบจุดบกพร่องของแปลนให้กับเจ้าของบ้าน',
            adv: 'ข้อดี ของการมีคู่หูดูแปลนบ้าน',
            detailAdv1: '✅ ได้แปลนบ้านที่มีคุณภาพแปลนสูงสุด',
            detailAdv2: '✅ ได้ผังและฟังก์ชั่นที่ดีกว่าการออกแบบทั่ว ๆ ไป',
            detailAdv3: '✅ ได้แปลนบ้านที่มีความถูกต้องแก่การอยู่อาศัย หลักธรรมชาติ และฮวงจุ้ยอย่างแท้จริง',
            detailAdv4: '✅ ช่วยให้ประหยัดงบในการแก้ไขบ้านในระยะสั้นและยาว เป็นมูลค่าหลายแสนบาท',
            detailAdv5: '✅ ประหยัดงบดูแลสุขภาพ จากการได้บ้านที่มีผังและฟังก์ชั่นถูกต้อง',
            priceRate: 'อัตราค่าบริการ คู่หูดูแปลนบ้าน',
            priceRate1: '1. บ้านขนาดต่ำกว่า 100 ตารางเมตร ค่าบริการ 3,500 บาท',
            priceRate2: '2. บ้านขนาดระหว่าง 100-200 ตารางเมตร ค่าบริการ 4990 บาท',
            priceRate3: '3. บ้านขนาดไม่เกิน 300 ตารางเมตร ค่าบริการ 5,990 บาท',
            vat: '* อัตราค่าบริการ รวมภาษีมูลค่าเพิ่มแล้ว *'
        },
        footer: {
            name: 'บริษัท แอสเซทอัพ จำกัด (สำนักงานใหญ่)',
            address: '318/92 ซอย ลาดพร้าว 87 (จันทราสุข) แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง จังหวัดกรุงเทพมหานคร 10310',
            copyright: 'สงวนลิขสิทธิ์',
            copyrightNameCompany: 'บริษัท แอสเซทอัพ จำกัด',
            tel: 'โทร.'
        }
    },
    en: {
        header: {
            name: 'Asset Up Co., Ltd',
            home: 'Home',
            about: 'About',
            contact: 'Contact'
        },
        home: {
            currentProject: 'Current Project',
            nameCurrentProject: 'Name Project',
            detailCurrentProject: 'Datail Project',
            nameCompany: 'Asset Up Co., Ltd',
            detailCompany: 'Design - Construction - Plan - Function',
            moreDetail: 'More detail',
            ourService: 'Our service',
            newContent: 'New content',
            pastProject: 'House for sale',
            design: 'House Design',
            construction: 'Home Building',
            planandfunction: 'House plan and function',
            plansuccess: 'Ready to Build house plan',
            pool: 'Swimming pool and Onzen',
            render: '3D Rendering',
            planlayout: 'Residential Project',
            twohandhome: 'House inspection partner',
            drone: 'Eagle view photo service',
            sell: 'House and Land Classified',
            readMore: 'Read more',
            contentPostTitle1: 'Asset Up Home Design and Construction Team With one of the best housing functions in Thailand',
            shortDescriptionPost1: 'The company is well aware that Quality of life of the residents of the house It would never have happened if the house ...',
            //Projects
            titleProject1: 'Single house outside the project.',
            priceProject1: '9.9 million baht.',
            shortDescriptionProject1: 'Sold with furniture, ready to move, just 10 minutes from Nimmanhaemin Road.',
            bedRoomProject1: '3 rooms.',
            toiletProject1: '3 rooms.',

            titleProject2: 'Single house outside the project.',
            priceProject2: '5.5 million baht.',
            shortDescriptionProject2: 'Sold with furniture.',
            bedRoomProject2: '3 rooms.',
            toiletProject2: '2 rooms.',

            titleProject3: 'Single house in city location.',
            priceProject3: '2.xx million baht.',
            shortDescriptionProject3: 'Say goodbye to the old standard houses and start something new here.',
            bedRoomProject3: '3 rooms.',
            toiletProject3: '2 rooms.',

            titleProject4: 'SD Condo Suan Dok',
            priceProject4: '1.89 million baht.',
            shortDescriptionProject4: 'Living room and kitchen size 40 sq m.',
            bedRoomProject4: '1 room.',
            toiletProject4: '1 room.',

            //Posts
            titlePost1: 'Asset Up Home Design and Construction Team With one of the best housing functions in Thailand.',
            ShortDescriptionPost1: 'Well, the company thinks that the quality of life of the residents of the house will never happen if the house ...',
            titlePost2: 'Want to build a house But do not know how to start?',
            ShortDescriptionPost2: 'One big problem The biggest headache for people wanting to build a house is that they do not know where to start. Today Asset Up ...',
            titlePost3: 'The origin of the liver pain question With a home builder How much per square meter? Episode 1',
            ShortDescriptionPost3: 'Read on to see the scatters on the head. And it must have felt strange when I was about to build a house. I must want to know. That the cost of building a house How much will it cost ...',
            titlePost4: 'Detached house outside the Havena Saraphi project for those who have dreams To the house of happiness.',
            ShortDescriptionPost4: 'Believe that many people will face the same problem Time to reconfirm the completion of the house Ready to sell at ...',

        },
        contact: {
            contact: 'Contact us',
            contactDetail: 'Contact details',
            headOffice: 'Head office',
            addressOffice: '318/92 Soi Ladprao 87 (Chandrasuk), Klong Chao Khun Sing, Wang Thonglang, Bangkok 10310',
            socialMedia: 'Find us on',
            sendMessage: 'Send us a message',
            name: 'Name',
            email: 'Email',
            subject: 'Subject',
            message: 'Message',
            send: 'Send'
        },
        singleProject: {
            price: 'Price',
            moreDetail: 'More details',
            location: 'Location',
            area: 'Area',
            gallery: 'Gallery',

            cateProject1: 'Single house outside the project.',
            description1Project1: 'Quality level beautiful house Ready to move in, designed and constructed with the only TUD layout and function in Thailand. It is more comfortable than home.',
            description2Project1: 'With a potential location That provides convenience in life Reach the city in just minutes Close to nature, just a few steps.',
            description3Project1: 'Usable area 260 square meters on land 54 square meters with dining counter, laundry corner, appliances, 5 air conditioners, mixed warm water. With rain shower And a bathtub, swimming pool, 2 * 7 meters, sea view seats. Fully furnished Ready to move in.',
            priceProject1: '9.9 million baht.',
            bedRoomProject1: '3 rooms.',
            toiletProject1: '3 rooms.',
            locationProject1: 'Suthep Subdistrict, just 10 minutes from Nimmanhaemin Road.',
            areaProject1: '260 square meters.',
            livingRoomProject1: '2 rooms.',

            cateProject2: 'Single house outside the project, good location at San Kamphaeng.',
            description1Project2: 'Suitable for people who want a home With a convenient location Located near the main road 1317, it is one of the most convenient places to travel in Chiang Mai. Getting in and out of the city Or various areas quickly with a 6-lane road around the direction.',
            description2Project2: '2-storey house, size 220 square meters, on 100 square meters of land, comes with internet, CCTV, water supply, village With filtering system, 3-4 parking spaces, with patio and lawn.',
            description3Project2: 'Sold with furniture.',
            priceProject2: '5.5 million baht.',
            bedRoomProject2: '3 rooms.',
            toiletProject2: '3 rooms.',
            locationProject2: 'San Kamphaeng District.',
            areaProject2: '220 square meters.',
            livingRoomProject2: '1 room.',

            cateProject3: 'Single house in the city of Havenna, Saraphi.',
            description1Project3: 'Single house, affordable price With function to understand the people Another convenient travel location Located at Tha Wang Tan Subdistrict, Saraphi District, between Ring 2 and Round 3.',
            description2Project3: 'Classic exterior The interior is superior with quality planning.',
            description3Project3: 'Usable area of ​​over 140 square meters on land starting from 50 square meters Kitchen with dining area Layout and functions that fit in all proportions.',
            priceProject3: '2.xx million baht.',
            bedRoomProject3: '3 rooms.',
            toiletProject3: '2 rooms.',
            locationProject3: 'Saraphi District.',
            areaProject3: '140 square meters.',
            livingRoomProject3: '1 room.',

            cateProject4: 'SD Condo Suan Dok',
            description1Project4: 'Condo for those who like quiet, comfortable. In the center of the city, Suan Dok District, Chiang Mai near Nimmanhaemin And many convenient sources, including the airport, Ton Payom market, convenience store Department store Rim Ping Supermarket Suan Dok Hospital Chiangmai Ram Hospital Chiang Mai University.',
            description2Project4: 'And ideal For doctors and nurses Or those who are currently studying in various faculties in Suan Dok Hospital.',
            description3Project4: 'The condo comes with a kitchen and living room. 40 square meters of usable area with fully furnished furniture, 2 air conditioners, water heaters And washing machine With free laundry service 30 pieces / month and free room cleaning 2 times / month from central. **',
            priceProject4: '1.89 million baht.',
            bedRoomProject4: '1 room.',
            toiletProject4: '1 room.',
            locationProject4: 'Suan Dok',
            areaProject4: '40 square meters.',
            livingRoomProject4: '1 room.'
        },
        singlePost: {
            shareTitle: 'Share this post',
            shareFacebook: 'Share',
            tagDesign: 'Home Design.',
            tagBuild: 'Construction contractor.',
            tagCreate: 'Made to order quality houses.',
            tagOutside: 'House outside the project.',
            tagPlanProject: 'Project layout.',
            tagDesignFunction: 'Adjust the layout and design the function.',
            tagFunction: 'Home TUD function.',
            tagCreatePool: 'Build a swimming pool.',

            titlePost1: 'Asset Up Home Design and Construction Team With one of the best housing functions in Thailand.',
            description1Post1: 'The company is well aware that Quality of life of the residents of the house It will never happen. If that house only has a beautiful exterior But within the lack of proper layout and function to suit the actual living.',
            description2Post1: 'Lack of quality plans and functions for a residential home It is a long-rooted problem. Whether it is a repetitive internal house plan problem, improper placement of each part of the floor plan. Functional design that is not suitable for real life or even a house that has a plan Which resulted in illness Including accidents. These factors affect buying or building a house. It was one of the big losses in my life. While most people view having a home as an investment or a major turning point.',
            description3Post1: 'But actually The owner of the house never returned the value. Compared with the money that has to be paid out to build a house. Because you can only have a house with a beautiful exterior But the layout and internal functions lack both the proper and the correct.',
            quotesPost1: 'The presence of planning and function experts to take care of the housing design process. Help create superior perfection.',
            description4Post1: 'Asset Up realizes the importance of the above problem. Therefore creating a team of specialist function placement Which serves to develop house design and construction techniques Together with the team of architects and engineers.',
            description5Post1: 'Including training to upgrade the skill of construction workers of the company. To be able to construct a house that has functions suitable for living As determined by the design team Which the general construction team cannot do, such as cleaning the toilet, reducing accidents, etc.',
            description6Post1: 'The presence of a specialist in planning and function to take care of the housing design process. Help create superior perfection Making every house that design There is a house plan that truly corresponds to the nature of light, wind, water or the outside environment. And has a unique identity that is uniquely. Having the right layouts and functions It also creates tremendous cost savings. Since the construction of the house from the first time Reducing the need to fix and expand, which is a common problem that most people think is normal.',
            description7Post1: 'In the function of living Able to deliver quality of life beyond general house designs Suitable for all members of all ages, accommodating in every situation to be ready in terms of convenience and also preserve health And reduce accidents in the home It is an experience of accessing the quality of life in a typical home style. Can not be given.',
            description8Post1: 'All houses designed and constructed by Asset Up It is worthwhile in every way from the budget created. To the quality of life It is the answer that every home lover is truly looking for.',

            titlePost2: 'Want to build a house But still do not know how to start ??',
            ShortDescriptionPost2: 'One big problem The biggest headache for people wanting to build a house is that they do not know where to start. Today Asset Up ...',
            description1Post2: 'One big problem That causes the most headache of people wanting to build a house is I do not know where to start. Today at Set Up Will introduce how to plan To design and build homes in a professional way, but first, let us focus on what causes most people. Faced this problem as well',
            description2Post2: 'The first is the absence of a mentor. Because most people Build only one house in your life Except people who sell their homes as a profession That kind was seen as an exception. Therefore, having to come to specialize in the house would be difficult. Having a mentor is the right start. Consultant here Is that the design and construction company If any homeowner comes to sit and talk to the consultant team with Asset Up, it will be given a plan to get a picture. Explicit planning results in a seemingly difficult start to build a house. It has become so easy.',
            description3Post2: 'The second story is To talk to each other at home Or talk to someone who has no homework experience. Because people in the house did not have information To be used as a solution to the problem as well. Therefore, having a mentor Will shorten the time, reduce the headache, save money, make every obstacle There is an easy exit.',
            quotesPost2: 'Having a good mentor It will shorten time, reduce headache, save money, make every obstacle an easy solution.',
            description4Post2: 'The third thing is that it is very impatient. Asset-ups often encounter impatient customers. The nature of the problem is Impatient, skip step Skip to the finished house drawing and it appears the fact that the budget with the budget that has to be built. It does not go together Most of which is the actual budget that is higher than the budget that has that. The problem arises from the customer skipping the step to design the house. While not clearly planning the conditions and constraints, the designer designed it. Without control frame the last type that was acquired was a disaster because the price could not be created. If you were to build it in the same way, you had to squeeze everything down. Reduce the material quality according to Finally got a house with low structural quality. Problems spread after another 108.',
            description5Post2: 'At Asset Up, we place a simple 3-step planning sequence.',
            description6Post2: '1. Find a consultant to answer all your home building problems. Good advisor Will always eliminate all problems and doubts for homeowners If anyone is still unable to find it or is unsure, you can call and have a discussion. Talking a lot is the most important aspect of building a house. Because of the work that is understood Surely always brings the best results.',
            description7Post2: '2. Set a construction budget This step is going to be the opposite of what most people do. Is to run to find the construction price / square meter And compete to see who gives the cheaper price This is the cause of disaster in the building of a type of house. This will be explained in the following articles. Budgeting will set a framework for everything, including the control of budgeting to escalate as well.',
            description8Post2: '3. Write down what you want. Take two sheets of paper and one pen and sit in a meeting with the people in the house. Or ask yourself that our new home How do you want it to come out? What is in it? What kind of house do you want outside? Write as much as we can to extract the details of the house, then bring the latter 2 information to the consultant. And let the headache be the duty of the construction company. The owner of the house is responsible for just living as usual. And wait for the time to caress the plan of the house that will be a new future.',

            titlePost3: 'The origin of the liver pain question With a home builder How much per square meter ?? Episode 1',
            ShortDescriptionPost3: 'Read on to see the scatters on the head. And it must have felt strange when I was about to build a house. I must want to know. That the cost of building a house How much will it cost ...',
            description1Post3: 'Read on to see the scatters on the head. And it must have felt strange when I was about to build a house. I must want to know. That the cost of building a house How much will it cost ?? And asking like this, how is it wrong? How can I get liver pain? In fact, asking like this for building a house is a question asked from back and forth. And it is a way of raising house planning questions that cause escalation. Unbelievable, is it? But it is true because the house that was built and budget bloomed. There is definitely one reason for this. Before explaining the most important reason, it is necessary to mention the origin that went. Price per square meter. Where did we get it? But must agree to the rules before that The price for building houses per square meter that assets are going to be described below. The goal is to get a design job. And build a house in a high quality standard, budget control can finish at once Not a price to please the market, do not really do that, not in this deal. That must be understood like this first Because people tend to get used to the idea of ​​association. And a superficial understanding let us talk, so if we have an axle When talking to each other, it is easy to understand.',
            description2Post3: 'Because there are many homeowners talking to the Asset Up, here it is, here they give you a lower construction price. The price is lower Not at all wrong But in reality, the low price does not necessarily mean it is cheap. Because building a house is different from shopping Which cannot be compared on the principle of bidding at all It is just what we see, deeper into the skin, it is not visible. If taking home construction prices that are primarily low, enter that So it is not strange at all That there will be many homeowners who cannot actually build a house Created, the contractor left the job, building a house and budget escalate, take out more, poor structural quality, house spec does not match the price per square meter first, etc. This is just to lure homeowners to stick with the cheap construction price per square meter.',
            description3Post3: 'Calculating the price per square meter In building a house for washing, there is a process of steps as follows.',
            quotesPost3: '',
            description4Post3: 'The first is to complete the house plan.',
            description5Post3: 'Second place, we will have another document of the calculation of materials, also known as BOQ. And an autocad plan file to calculate the price. Because by experience it was found that Many times, the details in the material calculation list (BOQ) have frequent errors. Missing out important details As a result, the calculation of the construction price of the house was wrong as well. Which resulted in a misunderstanding Or arguing between construction companies And the owner of the house.',
            description6Post3: 'When all 3 parts of this information are obtained, it will be used to calculate the construction price of that house. The calculation is separated into the construction materials section. Including decoration work and in the part of wages, work and a little more according to the nature of the house But it is not considered very significant. When the construction figures are completed Will be divided by the usable area of ​​the house Therefore, the average house construction price per square meter will come out in the end. This method is to obtain the actual build price, the current price, the price that matches all the house specs. Is the price that allows us to get 1 house out of not more than No point. Because every detail is defined and controlled by all documents. Clearly.',
            description7Post3: 'When does the problem with liver pain occur? If you try the sequence of the stories that follow Until you see the origin of the house construction price per square meter, you can understand immediately Because most people Take the picture of the house that you like That is pleasing to look at and asked how to build a house like this How much square meter can you build? The correct answer is that I can not know at all. The question is, there are only pictures, details, nothing else. How can I give you the correct price? And if informed, will it be the price to actually build a house or not? Because it contradicts the method of calculating the construction price upside down.',
            description8Post3: 'When the validity is not The real disadvantage is the owner of the house. Because if the contract has already been made (At a sweet price) But when the construction has been started, there will be various expenses that will follow to be disbursed as a bill continuously, known as the budget escalate. (In fact it is the actual construction price that has to be paid But was blocked by the low price first) That is, what is the disadvantage? Of course, if there is a bill that is more than the originally agreed price Can not pay, can you, but the house is not finished. This is a full disadvantage. In the end, he presses how much he has to pay. It is true that part of the ethics is flawed by contractors. Or criminals who hide themselves. But the problem is that homeowners use low prices to dictate the game. Eyes glazed at the first price In the end, it had to be in full condition. When encountering like this, they have to continue to win, like a game show that the house spec will be repelled. What else is drugged? I could not sleep for many more years. I am having fun But you have to rest at Part 1 for this much. Let us continue with Part 2 again.',

            titlePost4: 'Detached house outside the Havena Saraphi project for those who have dreams To the house of happiness.',
            ShortDescriptionPost4: 'Believe that many people will face the same problem Time to reconfirm the completion of the house Ready to sell at ...',
            description1Post4: 'Believe that many people will face the same problem Time to reconfirm the completion of the house Ready for sale See why the house is all the same. Not just like the location, the look and the decoration, but there are many things that make us feel that the house is no different at all. Looking at any house, it all feels the same. If someone who has already had a home Come buy more, will end up feeling that Get a new house In the conventional sense, that is an unmatched loss for a large investment. Once in a lifetime.',
            description2Post4: 'But that is it, sir, the general public cannot speak, explain or tell what the real cause of the problem is. Today, At Setup will tell you this cosmic secret. The real reason for that feeling A house built for sale anywhere is the same, that is, "duplicate house plans".',
            description3Post4: 'Calculating the price per square meter In building a house for washing, there is a process of steps as follows.',
            quotesPost4: '',
            description4Post4: 'Yes, the houses that we see are built, sold or made to order. They were all built with the same plan, similar to only changing the appearance. And used to create new sales loop over and over. Therefore, it is not strange that many people buy a new home as if they got an old house That just looks new is the exterior that is more strange. So just in the corner of buying a new home in repetitive house plans is a painful loss. That many people feel, but cannot describe in words. Many people may have the next question. After all, the house can be in the same place, but the duplicate plan is just the beginning of all the problems.',
            description5Post4: 'This question, people who have a home, are clear. That when at home, I still feel strange and strange. There seems to be over here. It is not right. It is unlikely It is unlikely. Symptoms like this are called Problems from duplicate house plans Starting off, yes, because apart from the repetition of the house plan, the quality of the plan is not suitable for living.This is the second issue that follows, where this problem overlaps with the third problem, the function of the house. That is rich in problems.',
            description6Post4: '3 giant problems But it is a silent threat that erodes the feelings and the quality of life of people having a home to decay little by little, using the word quality of life may seem difficult. But if I say that most houses when they are finished, they have to be repaired, renovated, repaired, and this is not finished, causing problems to continue to solve. Finally, the house that we want to control, budget escalates. The wallet problem is likely to be more tangible and tangible.',
            description7Post4: 'All factors Is the source of the finished house ready for sale, Havenna classic style house that comes with house planning design And functions within to suit any living place. But not leaving the original key factor Since the direction of the house Convenient location And most importantly, the price is friendly. Not unlike a house with repetitive layouts and functions. Because the quality of life of a home is born from the care of the design team. That must have, along with knowledge, ability and understanding of the manner of life that are detailed Thinking back and forth in many angles, it will make a house that gives a difference in quality. But not different in price at all.',
            description8Post4: 'Create great value for your big investment in life with ready-to-sell houses, Havenna, Saraphi, the only place.'
        },
        serviceDesign: {
            startprice: 'The starting price is only 450-650 baht per sq m.',
            headtable: 'for project owners can contact to inform details of your project To receive a special price design proposal directly at 083-424-9090',
            krong: 'Pricing structure for project house design (use the same plan)',
            lang: 'the house that',
            priceplan: 'plan price',
            title1: 'Because of the correct home design',
            title2: 'is the first step towards having a good quality of life.',
            detialServiceDesign: 'Asset Up realizes home design problems in Thailand. That does not truly meet the needs of living Which is a problem that most people do not know And also used to focus on cheap house plans because they understand that house plans There is no difference It should be as professional as anyone who designs will not produce different results.',
            titleSuccess1: 'In the sense of the homeowner Getting a cheap house model',
            titleSuccess2: 'It is the first step of success.',
            titleSuccess3: 'whereas ...',
            titleSuccess4: 'That sense of accomplishment It is just a deceitful feeling. Because home designs are rich in mistakes that are common to it. Will bring problems to create headaches for homeowners all the time These problems will create cumulative stress. Until the loss of mental health It also has to pay a large sum of money to fix it.',
            titleSuccess5: 'Design mistakes made by the design team',
            titleTeam: 'The key reason why Asset Up is dedicated to creating a dedicated design team to help solve this problem.',
            detailTeam: 'By that specific team There is a spindle after the design of the flange. "Specialist programmers" who specialize in world-class planning and function TUD (True Universal Design) working alongside architects and engineers. Creating superior perfection that exceeds.',
            oneService: 'Another one of the best service in Thailand. Available only at Asset Up Company.',
            priceTitle: 'Standard design price.',
            price: 'Normal 450 baht per square meter, special only 350 baht / square meter.',
            standard: 'The standard set is defined as a work document consisting of',
            standard1: '1. Complete construction plan Including water, electricity, utilities.',
            standard2: '2. Architect is signature Inspection engineer.',
            standard3: '3. Place the powder and check the function chart with a specialized function planner.',
            standard4: '4. Calculation list (BOQ)',
            standard5: '5. Construction Permit (In case of letting the Asset Up to proceed with the construction).',
            standard6: '6. Consultation work during construction.',
            serviceSerm: 'Asset Up also offers a special price home plan design service. In addition to the standard set including',
            plan: 'Prefabricated house plans',
            serviceSerm2: 'To choose from, just specify your budget and your needs. And wait for the expert to contact you back For immediate consultation.',
            special: 'Very special for those who have contracted the construction with the company. We are ready to deliver construction house plans that come with layout and TUD function to homeowners for free !!'
        },
        serviceBuild: {
            photoClt: 'Photo collection of some design and construction works.',
            title1: 'Not only home design work That we raise the level of detail and attention to the part of the construction team Asset-ups are equally attentive.',
            title2: 'To make the construction work perfect Relate to the house plan that comes with the layout with TUD function.',
            subtitle1: 'Our construction team undergoes a special homework training on a regular basis every year. It also has more than 10 years of working experience in each department.',
            priceRate: 'Construction service rates.',
            detailPriceRate: 'Service fees in the construction section The company is classified into the following sections.',
            priceRate1: '1. Actual service fee From removing the list of materials brought by the customer.',
            priceRate2: '2. General material-specified service fee Prices are between 1x, xxx - 2x, xxx baht / sq m.',
            priceRate3: '3. Construction services with premium materials.',
            priceRateMore: 'Additional services',
            priceRateMore1: '1. Service bill removing bill of materials (BOQ)',
            priceRateMore2: '2. Advisory services, adjust plans and functions.',
            priceRateMore3: '3. Service fees for second-hand home viewing partners.',
            forCustomer: 'For customers who are interested in house plans with pre-built construction prices, please click',
            pleaseClick: 'Home design to order successfully.'
        },
        serviceHomeSuccess: {
            title1: 'For homeowners looking for convenience and speed from prefabricated house plans.',
            subtitle1: 'You can search for a house type from this category.',
            title2: 'Home designs of every design It has been designed with a full range of layout planning teams, architects and engineers. So you can be confident in the quality of the house plan.',
            plan1: 'House plan width 1 and 2 floors',
            plan2: '1 and 2 story deep house plans',
            plan3: 'Minimalist house design',
            plan4: 'Thai style house',
            plan5: 'Coffee shop and shop style',
            plan6: 'Other',
            cate: 'House type category',
            cate1: 'House plan width 1 and 2 floors',
            cate2: '1 and 2 story deep house plans',
            cate3: 'Minimalist house design.',
            cate4: 'Thai style house',
            cate5: 'Coffee shop and shop style',
            cate6: 'Other'

        },
        serviceRender: {
            titleFeeling1: '1 image representing a million meanings',
            titleFeeling2: ' 1 million Good feelings come from seeing beautiful pictures.',
            detailTitle: 'Whether to own a house Who would like to have a beautiful house image to decorate as a souvenir in a chic corner of the house or the owner of the project Who want a house image for marketing.',
            titleServeiceRender: 'Asset Up offers 3D rendering and design services, as well as high-quality VR (Virtual Reality) video clip making.',
            catePricerate: 'Service rate category',
            catePricerate1: 'Floor plan design and drawing services.',
            catePricerate2: 'Home molding service',
            catePricerate3: '3d rendering of 1 storey house',
            catePricerate4: '3d rendering of 2 story house',
            catePricerate5: 'Rendering of housing projects',
            catePricerate6: '3D movie clips (Virtual Reality)',
        },
        serviceSwimmingPool: {
            title1: 'One more element That help create beauty And perfect for your dream home',
            title2: 'Is to have a swimming pool or onsen that will be the center of happiness. Relax for all members of the house. Or even as an appointment Meet up with friends That will change every weekday Can always be a special day.',
            asset: 'Asset Up has a quality team to provide consultancy, design and construction of the swimming pool. Along with the professional system implementation To suit your needs.',
            pricerate: 'Design and construction services',
            detailPricerate1: 'Because of such work There are different details according to the nature of the work page. Including the difference in pool size, soil type, pool system work Or an onsen system work Adding a water temperature stabilizer, etc.',
            detailPricerate2: 'The company reserves the right to check on site. Therefore will be able to inform the details in the following order.',
            detailMore: 'Additional information',
            detailmore1: '1. The pool system is sucked back.',
            detailmore2: '2. Overflow swimming pool system',
            detailmore3: '3. Onsen system',
            detailmore4: '4. The difference of prefabricated swimming pools With the structural steel reinforced concrete structure',
            detailmore5: '5. Swimming pool with piles',
            detailmore6: '6. Swimming pool thermostat',
        },
        servicePlanHouse: {
            title1: 'Housing project planning service',
            detailTitle: 'For those who do business in real estate developers',
            detailService: 'Asset Up is ready to be a part of building the foundation and growing together.',
            detailService2: 'With a full range of services That helps customers who wish to build houses for sale Able to start planning the work to the end of all steps quickly and see all the problems around the direction. Including being able to control the generating capital See profits clearly.',
            service: 'Housing project management service.',
            service1: '1. Overall project planning work.',
            service2: '2. Home design work for projects.',
            service3: '3. Electrical systems, utilities, treatment systems, etc. within the project.',
            service4: '4. Application for permission for housing projects Or a house outside the project.',
            service5: '5. Marketing planning public relations.',
            service6: '6. Home and Land Marketing Online.',
            service7: '7. Logo and poster design services for company publicity.',
            service8: '8. Other necessary for housing projects.',
        },
        servicePlanFunction: {
            planHouseTitle: 'There are many differences between the house plans, and the quality is good and bad.',
            houseOwner: 'Most homeowners never know.',
            detailPlan : 'The homeowner cannot see the flaws of the layout and function of the house plan. When the house was built The owner of the house is in a position of servitude. Forbearance in the house is rich in such shortcomings. It is also the primary cause. That spread to various problems, including the need to waste the budget to solve unnecessarily.',
            assetupService: 'Asset Up offers a companion to view home plans. To help fix the problem.',
            detailService: 'For customers who have not designed a house plan with us By a partner to see plans Will help verify the layout Including managing all the functions of the house How perfect it is The information is then collected for the homeowners to send back to the architects who designed the house for the customer. Has made further adjustments.',
            detailService2: 'Such method Will help customers achieve a good level of quality of life Reduce the repeated use of house plans, save the burden of costs and expenses that have to be fixed, repaired, renovated, which will result in enormous amount.',
            priceRate: 'Service rates Home Plan Buddy.',
            priceRate1: '1. House size less than 100 square meters, service fee 3,500 baht',
            priceRate2: '2. House size between 100-200 square meters, service fee 4990 baht',
            priceRate3: '3. House size not over 300 square meters, service fee 5,990 baht.',
            vat: '* Service rates VAT excluded. *'
        },
        footer: {
            name: 'Asset Up Co., Ltd.',
            address: '318/92 Soi Ladprao 87 (Chandrasuk), Klong Chao Khun Sing, Wang Thonglang, Bangkok 10310',
            copyright: 'COPYRIGHT ALL RIGHTS RESERVED',
            copyrightNameCompany: 'ASSETUP CO., LTD',
            tel: 'Tel.'
        }
    },
}

//Language
const i18n = createI18n({
    locale: localStorage.getItem("lang") || 'th',
    fallbackLocale: 'en',
    messages,
})

export default i18n;