<template>
    <div class="footer" id="footer">
		<div class="container">
			<div class="row">
				<div class="col-sm-1 footer-col">
					<div class="footer-logo">
						<a href="/"><img v-lazy="{src: require('@/assets/logo-gold.webp')}" alt=""></a>
					</div>
				</div>
				<div class="col-sm-9 footer-col">
					<p>{{ $t("footer.name")}}</p>
					<p class="small">{{ $t("footer.address")}}</p>
					<p class="small">{{ $t("footer.tel")}} 081 999 7722, 083 424 9090</p>
					<div class="line-it-button" data-lang="en" data-type="friend" data-lineid="silpaphat" style="display: none;"></div>
				</div>
				<div class="col-sm-2 footer-col">
					<!-- social links -->
					<ul class="social-links list-inline">
						<li><a href="https://www.facebook.com/TheAssetUp/" target="_blank"><span class="fa fa-facebook-square"></span></a></li>
						<li><a href="mailto:chiangmaiasset@gmail.com"><span class="fa fa-envelope-square"></span></a></li>
						<li><a href="tel:0819997722"><span class="fa fa-phone-square"></span></a></li>
					</ul>
					<!-- /social links -->
				</div>
			</div>		
		</div>		
	</div>
	<div class="copyright">
		<p class="copyright">©2021-2022 {{ $t("footer.copyright")}} | {{ $t("footer.copyrightNameCompany")}}</p>
	</div>
</template>
<script>
export default {
    
}
</script>
