<template>
    <div class="cookie">
        <cookie-consent :cookie-expiry-days="30" >
            <template v-slot:message>
                <p class="uk-margin-remove-bottom">เราใช้คุ้กกี้เพื่อให้ท่านได้รับประสบการณ์ที่ดี การใช้งานเว็บไซต์นี่ต่อไป ถือว่าท่านยินยอมให้ บริษัท แอสเซทอัพ จำกัด จัดเก็บ รวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคลของท่าน </p>
                    
            </template>
            <template v-slot:button>
                <a style="background-color: white; color: black !important; padding: 5px 10px; margin-left: 0.5rem;" class="nb-accept">ยอมรับ</a>
            </template>
        </cookie-consent>
    </div>
    <!-- Page Loader
    ========================================================= -->

    <!-- Full Screen Menu
    ================================================== -->
    <div class="overlay-menu" v-show="overlayMenu">
        <span class="overlay-close" id="overlay-close">&nbsp;<i class="fa fa-times"></i></span>
        <nav class="main-menu">
            <div class="menu-logo">
                <a href="/"><img v-lazy="{src: require('@/assets/logo-gold.webp')}" alt=""></a>
            </div>
            <ul>
                <li v-on:click="overlayMenu = false"><router-link to="/">{{ $t("header.home")}}</router-link></li>
                <li v-on:click="overlayMenu = false"><a href="https://blog.assetup.co.th/">Blog</a></li>			
                <li v-on:click="overlayMenu = false"><router-link to="/contact">{{ $t('header.contact')}}</router-link></li>
                <li>
                    <span class="fa fa-globe"></span>&nbsp;
                    <select class="lang-selectbtn2" v-model="language" @change="changeLanguage()">
                        <option value="th" class="opt-lang">ไทย</option>
                        <option value="en" class="opt-lang">EN</option>
                    </select>
                </li>
            </ul>

            <div class="social-icons">
                <a href="https://www.facebook.com/TheAssetUp/" target="_blank" class="margin-social-icons"><i class="fa fa-facebook"></i></a>
                <a href="mailto:assetup@gmail.com" target="_blank" class="margin-social-icons"><i class="fa fa-envelope"></i></a>
                <a href="tel:0819997722" target="_blank" class="margin-social-icons"><i class="fa fa-phone"></i></a>
            </div>	
        </nav>
    </div>
    <!-- /Full Screen Menu
    ================================================== -->
    <div id="secondary-header">
        <div class="container">
            <div class="row">
                <div class="col-sm-4">
                    <p><span class="fa fa-building"></span>&nbsp;&nbsp; {{ $t("header.name") }}</p>
                </div>
                <div class="col-sm-4 text-center">
                    <a href="tel:0819997722" class="telAndEmail"><span class="fa fa-phone"></span>&nbsp;&nbsp; 081-999-7722</a>, <a href="tel:0834249090" class="telAndEmail">&nbsp; 083-424-9090</a>
                </div>
                <div class="col-sm-4 text-right">
                    <span class="fa fa-globe"></span>&nbsp;
                    <select class="lang-selectbtn" v-model="language" @change="changeLanguage()">
                        <option value="th" class="opt-lang">ไทย</option>
                        <option value="en" class="opt-lang">EN</option>
                    </select>
                </div>
            </div>		
        </div>
    </div>

    <header id="header" class="navbar navbar-default"> 
        <div class="stick-bg"></div>
        <div class="container header-container">
            <div class="row">
                <!-- col -->
                <div class="col-sm-6">
                    <!-- logo -->	   
                    <div class="hd-logo hideOnMobile">
                        <a href="/"><img v-lazy="{src: 'https://blog.assetup.co.th/wp-content/uploads/2021/08/logo01.png'}" alt=""></a>			      	
                    </div>
                    <div class="hd-logo hideOnDesktop">
                        <a href="/"><img v-lazy="{src: 'https://blog.assetup.co.th/wp-content/uploads/2021/08/ทอง-1.png'}" alt=""></a>			      	
                    </div>	   
                    <!-- /logo -->
                </div>
                <!-- /col -->
                <!-- col -->
                <div class="col-sm-6">			
                    <!-- responsive menu -->
                    <div class="hd-menu">
                        <div class="a">
                            <a href="https://line.me/ti/p/k1mguc-G81" class="demoAvenueLine"><img v-lazy="{src: require('@/assets/line.png')}"> <span style="color: #787878; opacity: 0.4;"> | </span>Add LINE</a>
                        </div>
                        <span class="btn-menu" @click="overlayMenu = true"><i class="fa fa-bars"></i></span>
                    </div>		    
                    <!-- /responsive menu -->
                </div>
                <!-- /col -->
            </div>  	   
        </div>	
    </header>
</template>
<script>
import CookieConsent from 'vue-cookieconsent-component'
export default {
    components: {
        CookieConsent
    },
    data() {
        return {
            language: null,
            overlayMenu: true
        };
    },
    methods: {
        changeLanguage() {
            localStorage.setItem("lang", this.language);
            window.location.reload();
        }
    },
    created() {
        if(localStorage.getItem("lang") == null) {
            localStorage.setItem("lang", "th");
        }
        this.language = localStorage.getItem("lang");
    }
}
</script>
<style scoped>
.cookie {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
}
.cookie-consent {
    background-color: black !important;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-content: center;
    justify-content: center;
    height: 60px;
    color: white;
    font-size: 14px !important;
}
.cookie-consent p {
        font-size: 16px;
    }
    .cookie-consent a {
        color: white;
        font-size: 16px;
    }
@media (min-width: 960px) and (max-width: 1199px) {
    .cookie-consent {
        background-color: black !important;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-content: center;
        justify-content: center;
        height: 60px;
        color: white;
        font-size: 11px;
    }
    .cookie-consent p {
        font-size: 13px;
    }
    .cookie-consent a {
        color: white;
        font-size: 13px;
    }
}
@media (min-width: 640px) and (max-width: 959px) {
    .cookie-consent {
        background-color: black !important;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-content: center;
        justify-content: center;
        height: 120px;
        color: white;
        font-size: 12px;
        line-height: 18px;
        font-weight: 300;
    }
    .cookie-consent p {
        margin-bottom: 20px !important;
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
    }
    .cookie-consent p > a {
        margin: 0px !important;
    }
    .cookie-consent a.nb-accept {
        color: white;
        font-weight: 400;
    }
}
@media (max-width: 639px) and (min-width: 0px) {
    .cookie-consent {
        background-color: black !important;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-content: center;
        justify-content: center;
        height: 120px;
        color: white;
        font-size: 12px;
        line-height: 18px;
        font-weight: 300;
    }
    .cookie-consent p {
        margin-bottom: 20px !important;
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
    }
    .cookie-consent p > a {
        margin: 0px !important;
    }
    .cookie-consent a.nb-accept {
        color: white;
        font-weight: 400;
    }
}
</style>