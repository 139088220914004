import { createRouter, createWebHashHistory} from 'vue-router'
import Home from './views/Home.vue'

const routes = [
    { 
        path: '/',
        component: Home,
        meta: {
            title: 'Home Page | Asset Up - รับออกแบบบ้าน สร้างบ้าน วางผัง'
        }
    },
    { 
        path: '/contact', 
        component: () => import('./views/Contact'),
        meta: {
            title: 'Contact Page | Asset Up - รับออกแบบบ้าน สร้างบ้าน วางผัง'
        }
    },
    { 
        path: '/single-project/:PjId', 
        name: 'single-project', 
        component: () => import('./views/SingleProject'),
        // meta: {
        //     title: 'Project Page | Asset Up - รับออกแบบบ้าน สร้างบ้าน วางผัง'
        // } 
    },
    { 
        path: '/:pathMatch(.*)*', 
        component: () => import('./views/NotFoundError'),
        meta: {
            title: '404 Page Not Found | Asset Up - รับออกแบบบ้าน สร้างบ้าน วางผัง'
        } 
    },
    {
        path: '/all-post',
        component: () => import('./views/AllPost')
    },
    {
        path: '/service-design',
        component: () => import('./views/ServiceDesign')
    },
    {
        path: '/service-build',
        component: () => import('./views/ServiceBuild')
    },
    {
        path: '/service-home-success',
        component: () => import('./views/ServiceHomeSuccess')
    },
    {
        path: '/service-render',
        component: () => import('./views/ServiceRender')
    },
    {
        path: '/service-plan-function',
        component: () => import('./views/ServicePlanFunction')
    },
    {
        path: '/service-swimming-pool',
        component: () => import('./views/ServiceSwimmingPool')
    },
    {
        path: '/service-plan-house',
        component: () => import('./views/ServicePlanHouse')
    },
    {
        path: '/service-buddy-two-house',
        component: () => import('./views/ServiceBuddyTwoHouse')
    },
    {
        path: '/service-home-success/wide-front-house',
        component: () => import('./views/wideFrontHouse/index')
    },
    { 
        path: '/service-home-success/wide-front-house/:slug',
        name: 'product-detail', 
        component: () => import('./views/wideFrontHouse/_slug'),
    },
    {
        path: '/service-home-success/thai-style-house',
        component: () => import('./views/thaiStyleHouse/index')
    },
    { 
        path: '/service-home-success/thai-style-house/:slug',
        name: 'product-detail-thai', 
        component: () => import('./views/thaiStyleHouse/_slug'),
    },
    {
        path: '/service-home-success/coffee-house',
        component: () => import('./views/coffeeHouse/index')
    },
    { 
        path: '/service-home-success/coffee-house/:slug',
        name: 'product-detail-coffee', 
        component: () => import('./views/coffeeHouse/_slug'),
    },
    {
        path: '/service-home-success/narrow-house',
        component: () => import('./views/narrowHouse/index')
    },
    { 
        path: '/service-home-success/narrow-house/:slug',
        name: 'product-detail-narrow', 
        component: () => import('./views/narrowHouse/_slug'),
    },
    {
        path: '/service-home-success/minimal-house',
        component: () => import('./views/minimalHouse/index')
    },
    { 
        path: '/service-home-success/minimal-house/:slug',
        name: 'product-detail-minimal', 
        component: () => import('./views/minimalHouse/_slug'),
    },
    {
        path: '/service-home-success/etc-house',
        component: () => import('./views/etcHouse/index')
    },
    { 
        path: '/service-home-success/etc-house/:slug',
        name: 'product-detail-etc', 
        component: () => import('./views/etcHouse/_slug'),
    },
    {
        path: '/service-sale-house-land',
        component: () => import('./views/serviceSaleHouse')
    },
    {
        path: '/testform',
        component: () => import('./views/testform')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;