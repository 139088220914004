<template>
    <div id="body-content">	

        <!-- HERO intro -->
        <div class="section-hero" :style="{'background-image': 'url(' + require('@/assets/slide-hero-1.webp') + ')'}">
            <div class="hero-slide-wrapper hero-intro">
                <div class="container">
                    <!-- <div class="logo-title">
                        <img v-lazy="{src: require('@/assets/assetup-logo-gold.svg')}" alt="" class="img-logo-title">
                    </div> -->
                    <h1 class="title1 hero-title"><strong>{{ $t("home.nameCompany")}}</strong></h1>	
                    <p class="title2 hero-subtitle">{{ $t("home.detailCompany")}}</p>	
                    <div class="hero-content">
                        <a href="" class="btn btn-default click-fade">{{ $t("home.moreDetail")}}</a>
                    </div>					
                </div>
            </div>		
            <!-- Background Slides -->
            <div class="hero-slide" id="hero-slide">
                <div class="hero-slide-item">
                    <div class="hero-slide-item-bg">
                        <img v-lazy="{src: require('@/assets/slide/5171B137-48F4-4BD5-B234-294369A9C727.webp')}" alt="แอสเซทอัพ รับสร้างบ้าน ออกแบบบ้าน">
                    </div>								
                </div>	
                <div class="hero-slide-item">
                    <div class="hero-slide-item-bg">
                        <img v-lazy="{src: require('@/assets/slide/96F6627D-7747-42C3-820D-EE2F3F48BB14.webp')}" alt="แอสเซทอัพ รับสร้างบ้าน ออกแบบบ้าน">
                    </div>								
                </div>	
                <div class="hero-slide-item">				
                    <div class="hero-slide-item-bg">
                        <img v-lazy="{src: require('@/assets/slide/918A207F-2F93-410E-A4AE-23A26C7FF8DD.webp')}" alt="แอสเซทอัพ รับสร้างบ้าน ออกแบบบ้าน">
                    </div>								
                </div>		
                <div class="hero-slide-item">
                    <div class="hero-slide-item-bg">
                        <img v-lazy="{src: require('@/assets/slide/66596E14-5229-4C4B-B3DF-D66AC9D26EAE.webp')}" alt="แอสเซทอัพ รับสร้างบ้าน ออกแบบบ้าน">
                    </div>								
                </div>					
            </div>		
        </div>
        <!-- /Hero INTRO -->

        <!-- Section: Projects -->
        <div class="section-projects section-projects-home">
            <div class="container">			
                <div class="row">
                    <div class="col-sm-1 title-rotate-wrapper">
                        <p class="title-rotate">{{ $t("home.currentProject")}}</p>
                    </div>
                    <div class="col-sm-11 slide-show">
                        <div class='slider'>
                            <input name="input-slider" id='input-slide-0' type="radio" class='input-slide input-slide-num' >
                            <input name="input-slider" id='input-slide-1' type="radio" class='input-slide input-slide-num' >
                            <input name="input-slider" id='input-slide-2' type="radio" class='input-slide input-slide-num' >
                            <input name="input-slider" id='input-slide-3' type="radio" class='input-slide input-slide-num' >
                            <input name="input-slider" id='input-slide-4' type="radio" class='input-slide input-slide-num' >
                            <input name="input-slider" id='input-slide-5' type="radio" class='input-slide input-slide-num' >
                            <input name="input-slider" id='input-slide-6' type="radio" class='input-slide input-slide-num' >
                            <input name="input-slider" id='input-slide-7' type="radio" class='input-slide input-slide-num' >
                            <input name="input-slider" id='input-slide-autoplay' type="radio" class='input-slide' checked>
                            <ul>
                                <li class='slide-0'></li>
                                <li class='slide-1'></li>
                                <li class='slide-2'></li> 
                                <li class='slide-3'></li>
                                <li class='slide-4'></li>
                                <li class='slide-5'></li>
                                <li class='slide-6'></li>
                                <li class='slide-7'></li>        
                            </ul>
                            <div class='slide-description'>
                                <label class='slide-0'>
                                    <img v-lazy="{src: require('@/assets/slide/6820618D-CD4F-4FC3-8F73-407031D1CA44.webp')}" alt="" class="slide-img">
                                </label>
                                <label class='slide-1'>
                                    <img v-lazy="{src: require('@/assets/slide/A3279C5C-0E08-44CA-AA89-5720E2E0D7EA.webp')}" alt="" class="slide-img">
                                </label>
                                <label class='slide-2'>
                                    <img v-lazy="{src: require('@/assets/slide/B43B3AF5-95DC-40BF-B5E3-50EAB47F5049.webp')}" alt="" class="slide-img">
                                </label>
                                <label class='slide-3'>
                                    <img v-lazy="{src: require('@/assets/slide/C85D9915-DD65-49B7-B27F-C95590948E81.webp')}" alt="" class="slide-img">
                                </label>
                                <label class='slide-4'>
                                    <img v-lazy="{src: require('@/assets/slide/CB598192-BF2E-4849-BF6E-47D4B675E2FF.webp')}" alt="" class="slide-img">
                                </label>
                                <label class='slide-5'>
                                    <img v-lazy="{src: require('@/assets/slide/F4D7A4B2-5266-45F7-BD43-B3504B1672CB.webp')}" alt="" class="slide-img">
                                </label>
                                <label class='slide-6'>
                                    <img v-lazy="{src: require('@/assets/slide/FE83AB25-050E-43E7-A355-31198A82DE78.webp')}" alt="" class="slide-img">
                                </label>
                                <label class='slide-7'>
                                    <img v-lazy="{src: require('@/assets/slide-hero-1.webp')}" alt="" class="slide-img">
                                </label>
                            </div>
                            <div class='slider-arrow-prev'>
                                <label class='slide-0' for='input-slide-0'></label>
                                <label class='slide-1' for='input-slide-1'></label>
                                <label class='slide-2' for='input-slide-2'></label>
                                <label class='slide-3' for='input-slide-3'></label>
                                <label class='slide-4' for='input-slide-4'></label>
                                <label class='slide-5' for='input-slide-5'></label>
                                <label class='slide-6' for='input-slide-6'></label>
                                <label class='slide-7' for='input-slide-7'></label>
                            </div>
                            <div class='slider-arrow-next'>
                                <label class='slide-0' for='input-slide-0'></label>
                                <label class='slide-1' for='input-slide-1'></label>
                                <label class='slide-2' for='input-slide-2'></label>
                                <label class='slide-3' for='input-slide-3'></label>
                                <label class='slide-4' for='input-slide-4'></label>
                                <label class='slide-5' for='input-slide-5'></label>
                                <label class='slide-6' for='input-slide-6'></label>
                                <label class='slide-7' for='input-slide-7'></label>
                            </div>        
                            <div class='slider-dot'>
                                <label class='slide-0' for='input-slide-0'></label>
                                <label class='slide-1' for='input-slide-1'></label>
                                <label class='slide-2' for='input-slide-2'></label>
                                <label class='slide-3' for='input-slide-3'></label>
                                <label class='slide-4' for='input-slide-4'></label>
                                <label class='slide-5' for='input-slide-5'></label>
                                <label class='slide-6' for='input-slide-6'></label>
                                <label class='slide-7' for='input-slide-7'></label>
                            </div>        
                        </div>
                        <!-- <div id="slider-wrapper">
                            <div class="inner-wrapper"> -->
                                <!-- <input checked type="radio" name="slide" class="control" id="Slide1" />
                                <label for="Slide1" id="s1"></label>
                                <input type="radio" name="slide" class="control" id="Slide2" />
                                <label for="Slide2" id="s2"></label>
                                <input type="radio" name="slide" class="control" id="Slide3" />
                                <label for="Slide3" id="s3"></label>
                                <input type="radio" name="slide" class="control" id="Slide4" />
                                <label for="Slide4" id="s4"></label> -->
                                <!-- <div class="overflow-wrapper">
                                    <img v-lazy="{src: require('@/assets/586CF0E9-E3EF-495F-9F7A-22243A500D80.webp')}" alt="" class="slide">
                                    <img v-lazy="{src: require('@/assets/DJI_0139.webp')}" alt="" class="slide">
                                    <img v-lazy="{src: require('@/assets/D21B5982-DCFB-49BD-A251-0387077AFA19.webp')}" alt="" class="slide">
                                    <img v-lazy="{src: require('@/assets/F7D2A808-8FC7-43D6-9277-5EE2363EC8BE.webp')}" alt="" class="slide">
                                </div> -->
                            <!-- </div>
                        </div> -->
                    </div>
                </div>				
            </div>
        </div>	
        <!-- /Section: Projects -->

        <!-- SECTION: Service -->
        <div class="section-features fixed-bg1">
            <div class="container">
                <p class="title2 title-border title-section">{{ $t("home.ourService")}}</p>
                <div class="row row-custom">				
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                             <router-link @click="goToTopPage()" to="/service-design"><img v-lazy="{src: require('@/assets/sketch.webp')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-design" class="router-home"><p class="feature-title">{{ $t("home.design")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link @click="goToTopPage()" to="/service-build"><img v-lazy="{src: require('@/assets/construction.webp')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-build" class="router-home"><p class="feature-title">{{ $t("home.construction")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link @click="goToTopPage()" to="/service-plan-function"><img v-lazy="{src: require('@/assets/plan.webp')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-plan-function" class="router-home"><p class="feature-title">{{ $t("home.planandfunction")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link @click="goToTopPage()" to="/service-home-success"><img v-lazy="{src: require('@/assets/model.webp')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-home-success" class="router-home"><p class="feature-title">{{ $t("home.plansuccess")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link @click="goToTopPage()" to="/service-swimming-pool"><img v-lazy="{src: require('@/assets/swimming-pool.webp')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-swimming-pool" class="router-home"><p class="feature-title">{{ $t("home.pool")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link @click="goToTopPage()" to="/service-render"><img v-lazy="{src: require('@/assets/render.webp')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-render" class="router-home"><p class="feature-title">{{ $t("home.render")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link @click="goToTopPage()" to="/service-plan-house"><img v-lazy="{src: require('@/assets/design.webp')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-plan-house" class="router-home"><p class="feature-title">{{ $t("home.planlayout")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link @click="goToTopPage()" to="/service-buddy-two-house"><img v-lazy="{src: require('@/assets/friend.svg')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-buddy-two-house" class="router-home"><p class="feature-title">{{ $t("home.twohandhome")}}</p></router-link>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-sm-3 feature-item"></div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <a href="https://blog.assetup.co.th/product/service-drone/"><img v-lazy="{src: require('@/assets/svg/drone.webp')}" alt="" class="icon-feature"></a>
                        </div>
                        <a href="https://blog.assetup.co.th/product/service-drone/" class="router-home"><p class="feature-title">{{ $t("home.drone")}}</p></a>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link @click="goToTopPage()" to="/service-sale-house-land"><img v-lazy="{src: require('@/assets/svg/management.webp')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link @click="goToTopPage()" to="/service-sale-house-land" class="router-home"><p class="feature-title">{{ $t("home.sell")}}</p></router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- /SECTION: Service -->

        <!-- SECTION: Past project -->
        <div class="section-past-project">
            <div class="container">
                <p class="title2 title-border">{{ $t("home.pastProject")}}</p>
                <div class="container-fuild">
                    <div class="row">
                        <div class="col-sm-3" v-for="(project, index) in projects" :key="index">
                            <div class="project-box">
                                <a @click="goToSingleProject(project.projectId)"><img v-lazy="{src: project.img}" alt="" class="img-box-project"></a>
                                <div class="detail-project-box">
                                    <a @click="goToSingleProject(project.projectId)" class="title-box"><p class="title-box-project">{{project.cateProject}}</p></a>
                                    <p class="price-box-project">{{project.price}}</p>
                                    <p class="detail-box-project">{{project.shortDescription}}</p>
                                    <hr class="style1">
                                        <div class="row row-detail">
                                            <div class="col-sm-6"><span class="fa fa-bed"></span>&nbsp;&nbsp;{{project.bedRoom}}</div>
                                            <div class="col-sm-6"><span class="fa fa-bath icon-detail"></span>&nbsp;&nbsp;{{project.toilet}}</div>
                                        </div>
                                    <hr class="style1">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /SECTION: Past project -->

        <!-- SECTION: Youtube -->
        <div class="section-features fixed-bg1">
            <div class="container">
                <p class="title2 title-border title-section">Home Building Video</p>         
                <div class="row">
                    <div class="col-sm-6">
                        <div class="videoWrappper">
                            <iframe class="pb-2" width="450" height="315" src="https://www.youtube.com/embed/vYjHWCPTDOU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        
                        <!-- <a href="https://www.facebook.com/TheAssetUp/videos/989276935285535" target="blank"><img v-lazy="{src: require('@/assets/youtube1.webp')}" alt="" class="item-youtube"></a> -->
                    </div>
                    <div class="col-sm-6">
                        <div class="videoWrappper">
                            <iframe class="pb-2" width="450" height="315" src="https://www.youtube.com/embed/_tiSXAnifsY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <!-- <a href="https://www.facebook.com/TheAssetUp/videos/870084883655367" target="blank"><img v-lazy="{src: require('@/assets/youtube2.webp')}" alt="" class="item-youtube"></a> -->
                    </div>
                </div>
                <a class="router-video" href="https://www.youtube.com/channel/UCCJbQHZVQIAr1HEeQI5ivKA"><h3 class="text-center mt-1">ดูวิดีโอทั้งหมด</h3></a>
            </div>
        </div>
        <!-- /SECTION: Youtube -->

        <!-- SECTION: New content -->
        <div class="section-team">
            <div class="container">
                <p class="title2 title-border">{{ $t("home.newContent")}}</p>
                <carousel :breakpoints="breakpoints">
                    <slide v-for="post in posts" :key="post.id">
                        <div class="team-item-wrapper">
                            <div class="team-member-picture-wrapper">
                                <div class="team-item-picture">
                                    <img v-if="post._embedded['wp:featuredmedia']" :src="post._embedded['wp:featuredmedia'][0].source_url" alt="">
                                </div>
                                <div class="team-member-find">
                                    <div class="team-member-find-overlay">
                                        <div class="team-member-find-content">
                                            <a class="block-link" :href="`https://blog.assetup.co.th/`+ post.slug"><span class="fa fa-search-plus"></span></a>
                                        </div>									
                                    </div>								
                                </div>
                            </div>
                            <div class="team-item-content">
                                <p class="team-item-title">{{ post.title.rendered }}</p>
                                <div class="team-item-subtitle" v-html="post.excerpt.rendered"></div>
                                <a class="btn-read-more" :href="`https://blog.assetup.co.th/`+ post.slug">{{ $t('home.readMore')}} <span class="fa fa-arrow-right"></span></a>								
                            </div>
                        </div>
                    </slide>
                    <template #addons>
                        <pagination />
                    </template>
                </carousel>
            </div>
        </div>
        <!-- /SECTION: New content -->

        <!-- Section: Call to Action-->
        <div class="section-cta">
            <div class="container">
                <div class="section-cta-content">
                    <div class="row">
                        <div class="col-sm-7">
                            <p class="cta-text1">{{ $t('home.question')}}</p>
                        </div>
                        <div class="col-sm-5 text-right">
                            <a href="https://goo.gl/maps/xYLt5qiCKdZ42Aqq5" class="btn btn-mini"><span class="fa fa-building"></span>&nbsp; {{ $t('home.office')}}</a>
                            <a href="mailto:assetup@gmail.com" class="btn"><span class="fa fa-paper-plane"></span> &nbsp;{{ $t('home.lineID')}}</a>
                        </div>
                    </div>
                </div>			
            </div>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    components: {
        Carousel,
        Slide,
        Pagination
    },
    data() {
        return {
            selectedTag: null,
            breakpoints: {
                451: {
                    itemsToShow: 1.2,
                    snapAlign: 'center',
                },
                700: {
                    itemsToShow: 3.2,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 4.2,
                    snapAlign: 'center',
                },
            },
            projects: [
                {
                    projectId: 1,
                    img: require('../../src/assets/singleHouse-white-soldout.webp'),
                    cateProject: this.$t('home.titleProject1'),
                    price: this.$t('home.priceProject1'),
                    shortDescription: this.$t('home.shortDescriptionProject1'),
                    bedRoom: this.$t('home.bedRoomProject1'),
                    toilet: this.$t('home.toiletProject1')
                },
                {
                    projectId: 2,
                    img: require('../../src/assets/singleHouse-sankampang.webp'),
                    cateProject: this.$t('home.titleProject2'),
                    price: this.$t('home.priceProject2'),
                    shortDescription: this.$t('home.shortDescriptionProject2'),
                    bedRoom: this.$t('home.bedRoomProject2'),
                    toilet: this.$t('home.toiletProject2')
                },
                {
                    projectId: 3,
                    img: require('../../src/assets/havenna.webp'),
                    cateProject: this.$t('home.titleProject3'),
                    price: this.$t('home.priceProject3'),
                    shortDescription: this.$t('home.shortDescriptionProject3'),
                    bedRoom: this.$t('home.bedRoomProject3'),
                    toilet: this.$t('home.toiletProject3')
                },
                {
                    projectId: 4,
                    img: require('../../src/assets/SD-Condo-1.webp'),
                    cateProject: this.$t('home.titleProject4'),
                    price: this.$t('home.priceProject4'),
                    shortDescription: this.$t('home.shortDescriptionProject4'),
                    bedRoom: this.$t('home.bedRoomProject4'),
                    toilet: this.$t('home.toiletProject4')
                },
            ]
        }
    },
    computed: {
        posts() {
            return this.$store.state.posts;
        },
    },
    created() {
        this.$store.dispatch("getPosts");
    },
    methods: {
        goToSingleProject(ProjectId) {
            window.scrollTo(0,0)
            this.$router.push ({name: 'single-project', params:{PjId:ProjectId}})
        },
        goToTopPage() {
            window.scrollTo(0,0)
        },
        updateTag(tag) {
            if (!this.selectedTag) {
                this.selectedTag = tag.id;
            } else {
                this.selectedTag = null;
            }
        }
    }
}
</script>
<style>
    .pb-2 { padding-bottom: 20px; }
    .router-video { color: black; text-decoration: underline; }
    .router-video:hover { color: #d5b147; }
    /* #slider-wrapper {
    overflow: hidden;
    height: 510px;
    width: 728px;
    margin: 0 auto;
    }
    .inner-wrapper {
    width: 2912px;
    -webkit-animation: slide 14s ease infinite;
    }
    .slide {
    float: left;
    height: 500px;
    width: 728px;
    }
    @-webkit-keyframes slide {
    20% {margin-left: 0px;}
    30% {margin-left: -728px;}
    50% {margin-left: -728px;}
    60% {margin-left: -1456px;}
    80% {margin-left: -1456px;}
    } */
    .btn-mini{
        margin-right: 10px;
    }
    .router-home{
        color: black;
        cursor: pointer;
    }
    .router-home:hover{
        text-decoration: none;
        color: black;
    }
    .carousel{
        cursor: grab;
    }
    .carousel__pagination{
        margin-top: 5%;
        padding-left: 0;
    }
    .carousel__pagination-button{
        height: 10px;
        border-radius: 50%;
    }
    .carousel__slide{
        padding-right: 2%;
        align-items: flex-start;
    }
    @media (min-width: 451px) {
        .carousel__slide{
            padding-right: 5%;
        }
    }
    @media (min-width: 700px) {
        .carousel__slide{
            padding-right: 2%;
        }
    }
    .carousel__viewport{
        height: auto;
    }
    @media (min-width: 1400px) {
        .carousel__viewport{
            height: 520px;
        }
    }
    .carousel__pagination-button{
        background: black;
        opacity: 0.4;
    }
    .carousel__pagination-button--active{
        background: black;
        opacity: 1;
    }
    .mt-1 { margin-top: 40px; }
</style>