import { createStore } from "vuex";
import axios from "axios";

const siteURL = "https://blog.assetup.co.th/"

const store = createStore({
    state:{
        posts: [],
        tags: [],
        wideHouse: [],
        thaiHouse: [],
        coffeeHouse: [],
        narrowHouse: [],
        minimalHouse: [],
        etcHouse: [],
    },
    mutations: {
        updatePosts: (state, posts) => {
            state.posts = posts
          },
        updateTags: (state, tags) => {
            state.tags = tags
        },
        updateWideHouse: (state, wideHouse) => {
          state.wideHouse = wideHouse
        },
        updateThaiHouse: (state, thaiHouse) => {
          state.thaiHouse = thaiHouse
        },
        updateCoffeeHouse: (state, coffeeHouse) => {
          state.coffeeHouse = coffeeHouse
        },
        updateNarrowHouse: (state, narrowHouse) => {
          state.narrowHouse = narrowHouse
        },
        updateMinimalHouse: (state, minimalHouse) => {
          state.minimalHouse = minimalHouse
        },
        updateEtcHouse: (state, etcHouse) => {
          state.etcHouse = etcHouse
        }
    },
    actions: {
      async getPosts({ commit }) {
        const { data } = await axios.get(`${siteURL}/wp-json/wp/v2/posts?_embed&per_page=5`)
        commit('updatePosts', data)
      },
      async getWideHouse({ commit }) {
        const { data } = await axios.get(`${siteURL}/wp-json/wp/v2/product?product_cat=31&_embed`)
        commit('updateWideHouse', data)
      },
      async getThaiHouse({ commit }) {
        const { data } = await axios.get(`${siteURL}/wp-json/wp/v2/product?product_cat=33&_embed`)
        commit('updateThaiHouse', data)
      },
      async getCoffeeHouse({ commit }) {
        const { data } = await axios.get(`${siteURL}/wp-json/wp/v2/product?product_cat=34&_embed`)
        commit('updateCoffeeHouse', data)
      },
      async getNarrowHouse({ commit }) {
        const { data } = await axios.get(`${siteURL}/wp-json/wp/v2/product?product_cat=35&_embed`)
        commit('updateNarrowHouse', data)
      },
      async getMinimalHouse({ commit }) {
        const { data } = await axios.get(`${siteURL}/wp-json/wp/v2/product?product_cat=36&_embed`)
        commit('updateMinimalHouse', data)
      },
      async getEtcHouse({ commit }) {
        const { data } = await axios.get(`${siteURL}/wp-json/wp/v2/product?product_cat=37&_embed`)
        commit('updateEtcHouse', data)
      },
    }
});

export default store;