import { createApp } from 'vue'
import VueLazyLoad from 'vue3-lazyload'
import VueAxios from "vue-axios";
import axios from "axios";
import OwlCarousel from 'v-owl-carousel'
import cookieconsent from 'vue-cookieconsent-component'

import App from './App.vue'
import store from '../store'
import i18n from "./i18n";
import router from './route'

createApp(App)
.use(router)
.use(i18n)
.use(VueLazyLoad)
.use(store)
.use(VueAxios, axios)
.use('carousel', OwlCarousel)
.use('cookie-consent', cookieconsent)
.mount('#app')
